import axios from "axios";
import { getAvsBaseUrl, getDeviceType } from "../../../utils/Utility";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";
import URLConstants from "../../../constants/URLs";
import Constants from "../../../constants/Constants";

const { AVS_API_URLS } = URLConstants;
const { AVS_CONFIG } = Constants;

export const getVODs = async (from, to, maxResults) => {
  // cached data
  const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
  const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);
  const regionId = getLocalStorage(LOCAL_STORAGE_KEYS.REGION_ID);
  const keyword = ""; // empty intentionally

  let url = getAvsBaseUrl(environment) + AVS_API_URLS.VOD;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());
  url = url.replace("$PROPERTY", AVS_CONFIG.PROPERTY_NAME);
  url = url.replace("$KEYWORD", keyword);
  url = url.replace("$FROM", from);
  url = url.replace("$TO", to);
  url = url.replace("$MAX_RESULTS", maxResults);
  url = url.replace("$REGION_ID", regionId);

  // sample: https://telus.prod.g.svc.tv.telus.net/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/TRAY/SEARCH/VOD?query=&from=0&to=99&maxResults=100&filter_regionId=88
  const result = await axios({
    url: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Avs-Refresh-Token": avs_refresh_token,
    },
    withCredentials: true,
  })
    .then((response) => {
      //console.log("getVOD action has succeed: " + JSON.stringify(response));

      if (response.data.resultCode === "OK") {
        // check containers for has more value
        if (response.data.resultObj.containers.length > 0) {
          // parse containers
          const vodList = parseContainers(response.data.resultObj.containers);

          return { result: true, vodList: vodList };
        } else {
          return { result: false, hasMore: false };
        }
      } else if (response.data.resultCode === "KO") {
        return { result: false, error: response.data.message };
      }

      return {
        result: false,
        error: "Failed to fetch channels",
      };
    })
    .catch((error) => {
      console.log(" getVODs action has been failed: " + JSON.stringify(error));

      //return { result: false, channels: null };
      return {
        result: false,
        error: error,
      };
    });

  return result;
};

const parseContainers = (containers) => {
  var vodList = [];

  containers.forEach((item) => {
    const containerId = item.id;
    const contentType = item.metadata.contentType;
    const title = item.metadata.title;
    const externalId = item.metadata.externalContentId;
    const asset = item.assets[0];
    const assetType = asset.assetType;
    const assetId = asset.assetId;

    if (assetType === "MASTER") {
      vodList.push({
        containerId: containerId,
        contentType: contentType,
        title: title,
        externalId: externalId,
        assetId: assetId,
      });
    }
  });

  return vodList;
};
