import React, { Component } from "react";
import { Input, Button, Select, Form, Row, Col } from "antd";
import { generatePlayerSource } from "./demos/DemoListHelper";
import { CONTENT_TYPES, DRM_TYPES } from "../../constants/DemoContents";
import { stopOldPlaybackSession } from "../../utils/PlaybackSessionManager";

class CustomTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHLS: false,
    };
  }

  onLoadClicked = async (value) => {
    const content = {
      content_type: value.content_type,
      url: value.media_url,
      license_type: value.license_type,
      license_url: value.license_url,
      license_cert_url: value.license_cert_url,
      subject: value.subject,
      token: value.auth_token
    };

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "CustomSourceOnClick",
      time: Date.now(),
      text: "onClick action has performed for the custom source",
      detail: content,
    });

    const source = await generatePlayerSource(content, this.props.updateTimeline);
    if (source !== null) {
      this.props.onContentSelected(null, source);

      // if there is an playback session, kill it
      stopOldPlaybackSession(this.props.updateTimeline);
    }
  };

  createContentTypesOptions = () => {
    // create an array for tree data.
    var arr = [];

    // put content types as root items.
    CONTENT_TYPES.forEach((type) => {
      // add the parent item to the main array.
      arr.push({
        label: type.title,
        value: type.id,
      });
    });

    return arr;
  };

  createDRMTypesOptions = () => {
    // create an array for tree data.
    var arr = [];
    var types = this.props.getSupportedDRMSystems();

    // put content types as root items.
    DRM_TYPES.forEach((type) => {
      // add the parent item to the main array.

      const index = types.findIndex((object) => {
        return Object.keys(object)[0] === type.title;
      });

      arr.push({
        label: type.title,
        value: type.id,
        disabled: !Object.values(types[index])[0],
      });
    });

    return arr;
  };

  onDRMTypeSelected = (item) => {
    const index = DRM_TYPES.findIndex((x) => x.title === "fairplay");
    const isHLS = item === DRM_TYPES[index].id;
    this.setState({ isHLS: isHLS });
  };

  render() {
    return (
      <Form name="custom_url" onFinish={this.onLoadClicked} style={{ width: "100%", alignItems: "center" }}>
        <Row gutter={[1, 0]}>
          <Col xs={24} sm={24} md={5}>
            <Form.Item name="content_type" rules={[{ required: true, message: "Please select a content type!" }]}>
              <Select placeholder="Content Type" options={this.createContentTypesOptions()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={19}>
            <Form.Item name="media_url" rules={[{ required: true, message: "Please input a media url!" }]}>
              <Input placeholder="Media url" allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={5}>
            <Form.Item name="license_type">
              <Select
                placeholder="DRM Type"
                options={this.createDRMTypesOptions()}
                onSelect={(item) => this.onDRMTypeSelected(item)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={19}>
            <Form.Item name="license_url">
              <Input placeholder="License URL" allowClear />
            </Form.Item>
          </Col>

          {this.state.isHLS && (
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="license_cert_url">
                <Input placeholder="License Certificate URL ( Required for HLS DRM Streams )" allowClear />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={24}>
            <Form.Item name="auth_token">
              <Input placeholder="Auth Token " allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item name="subject">
              <Input placeholder="Subject ( Required for DRM Streams ) " allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Load
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default CustomTab;
