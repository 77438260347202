import React, { Component } from "react";
import { Col, Row, Layout, Affix } from "antd";
import BitmovinPlayer from "./player/BitmovinPlayer";
import Tabs from "./tabs/TabsComponent";
import Diagrams from "./diagrams/DiagramsComponent";
import Stats from "./stats/StatsComponent";
import BitrateSlider from "./bitrate/BitrateSliderComponent";
import LatencySlider from "./latency/LatencySliderComponent";
import PassCodeCheck from "./PassCodeCheckComponent";
import Timeline1 from "./timeline/TimelineComponents";
import { setCookie, getCookie } from "../utils/cookie";

let packageJson = require("../../package.json");

const { Footer } = Layout;

class Main extends Component {
  componentDidMount() {
    // listen window scroll for pip
    window.addEventListener("scroll", this.checkScrollPosition);
  }

  constructor(props) {
    super(props);

    this.state = {
      isVerified: false,
      isReadyForPip: false,
      playSource: null,
    };

    // references
    this.player = React.createRef();
    this.diagrams = React.createRef();
    this.bitrateSlider = React.createRef();
    this.latencySlider = React.createRef();
    this.timeline = React.createRef();
  }

  onLoadContent = (playSource) => {
    this.setState({ ...this.state, playSource: playSource });
    this.stats.resetStats();
    this.diagrams.resetDiagrams();
    this.bitrateSlider.resetSlider();
    this.latencySlider.resetSlider();

    // notify player to load the source
    this.player.onContentChange(playSource);
  };

  checkScrollPosition = (data) => {
    const currentScrollPos =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (currentScrollPos === 0) {
      if (this.state.isReadyForPip) {
        // don't move
        this.setState({ isReadyForPip: false });
      }
    } else {
      if (!this.state.isReadyForPip) {
        // resize and display in pip
        this.setState({ isReadyForPip: true });
      }
    }
  };

  updateDiagrams = (object) => {
    this.diagrams.updateDiagrams(object);
  };

  updateBandwidth = (object) => {
    this.diagrams.updateBandwidth(object);
  };

  updateStats = (object) => {
    this.stats.updateStats(object);
  };

  updateTimeline = (object) => {
    this.timeline.updateTimeline(object);
  };

  updateStatsBeforePlaying = (object) => {
    this.stats.updateStatsBeforePlaying(object);
  };

  getSupportedDRMSystems = () => {
    return this.stats.getSupportedDRMSystems();
  };

  updateBitrateSlider = (object) => {
    this.bitrateSlider.updateBitrateSlider(object);
  };

  updateLatencySlider = (object) => {
    this.latencySlider.updateLatencySlider(object);
  };

  changeBitrateRange = (array) => {
    this.player.updateRangedVideoQuality(array);
  };

  changeTargetLatency = (latency) => {
    this.player.setTargetLatency(latency);
  };

  changeLowLatencyConfig = (isFallback, object) => {
    this.player.changeLowLatencyConfig(isFallback, object);
  };

  onVerified = (values: any) => {
    this.setState({
      isVerified: true,
    });

    setCookie("homepage-passcode", true, 365);
  };

  render() {
    if (getCookie("homepage-passcode") === "true") {
      return this.homepage();
    } else {
      return this.passCodeCheck();
    }
  }

  passCodeCheck() {
    return (
      <Layout
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <PassCodeCheck onVerified={this.onVerified} />
      </Layout>
    );
  }
  restartPlaySource = () => {
    const restartPlaySource = { ...this.state.playSource };
    restartPlaySource["options"] = {
      startOffset: 1,
      startOffsetTimelineReference: "start",
    };
    this.onLoadContent(restartPlaySource);
  };

  homepage() {
    return (
      <div>
        <Row gutter={[20, 20]} style={{ padding: 20, paddingBottom: 20 }}>
          <Col xs={24} sm={24} md={12}>
            <Affix
              style={
                this.state.isReadyForPip
                  ? {
                      position: "fixed",
                      zIndex: "2",
                      height: "300",
                      bottom: "20px",
                      right: "20px",
                      width: "auto",
                    }
                  : {}
              }
            >
              <BitmovinPlayer
                ref={(instance) => {
                  this.player = instance;
                }}
                updateBandwidth={this.updateBandwidth}
                updateDiagrams={this.updateDiagrams}
                updateStats={this.updateStats}
                updateStatsBeforePlaying={this.updateStatsBeforePlaying}
                updateBitrateSlider={this.updateBitrateSlider}
                updateLatencySlider={this.updateLatencySlider}
                updateTimeline={this.updateTimeline}
              />
              <button
                onClick={this.restartPlaySource}
                style={{
                  border: "none",
                  borderRadius: "3px",
                  width: "100px",
                  height: "30px",
                  background: "darkgoldenrod",
                  color: "white",
                  fontSize: "large",
                  cursor: "pointer",
                }}
              >
                Restart
              </button>
            </Affix>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Tabs
              onLoadContent={this.onLoadContent}
              updateTimeline={this.updateTimeline}
              getSupportedDRMSystems={this.getSupportedDRMSystems}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Diagrams
              ref={(instance) => {
                this.diagrams = instance;
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24}>
            <LatencySlider
              changeTargetLatency={this.changeTargetLatency}
              changeLowLatencyConfig={this.changeLowLatencyConfig}
              updateTimeline={this.updateTimeline}
              ref={(instance) => {
                this.latencySlider = instance;
              }}
            />
            <BitrateSlider
              changeBitrateRange={this.changeBitrateRange}
              updateTimeline={this.updateTimeline}
              ref={(instance) => {
                this.bitrateSlider = instance;
              }}
            />
            <Stats
              ref={(instance) => {
                this.stats = instance;
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Timeline1
              ref={(instance) => {
                this.timeline = instance;
              }}
            />
          </Col>
        </Row>
        <Footer style={{ textAlign: "center", height: 200 }}>
          Version: {packageJson.version} <br /> Build Release Date:{" "}
          {packageJson.buildReleaseDate}{" "}
        </Footer>
      </div>
    );
  }
}

export default Main;
