import React, { Component } from "react";
import { Space, Table, Tag, Collapse } from "antd";

const { Panel } = Collapse;

interface DataType {
  id: string;
  lang: string;
  label: string;
}

class AudioStatsComponent extends Component {
  columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
      width:150,
    },
    {
      title: "Language",
      key: "lang",
      dataIndex: "lang",
      width:200,
      render: (_, { lang }) => (
        <Tag color="geekblue" key={lang}>
          {lang.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Label",
      key: "label",
      width:150,
      render: (_, { label }) => (
        <Space size="middle">
          <a>{label}</a>
        </Space>
      ),
    },
  ];

  rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },

    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  renderTable = (title, array, key) => {
    if (array.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <div>
        <Collapse defaultActiveKey={key}>
          <Panel header={title} key={key}>
            <Table
              columns={this.columns}
              dataSource={array}
              size="small"
              pagination={false}
              bordered
              scroll={{ y: 160 }}
            />
          </Panel>
        </Collapse>
      </div>
    );
  };

  render() {
    if (this.props.tracks.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <div>
        {this.renderTable("Available Audio Tracks", this.props.tracks, "stats-available-audio-tracks")}
        <br />
      </div>
    );
  }
}

export default AudioStatsComponent;
