import React, { Component } from "react";
import { Card, Form, Input, Button, message } from "antd";

class PassCodeCheck extends Component {
  isLoading = false;

  onSubmit = (values: any) => {
    if (values.password === "T3lus@AVS" || values.password === "T3lusQA") {
      this.props.onVerified();
    } else {
      message.error("Password is invalid.");
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card title="Passcode Check" bordered={false} style={{ width: 300 }}>
          <Form
            name="login"
            onFinish={this.onSubmit}
            autoComplete="off"
            style={{ width: "100%", alignItems: "center" }}
          >
            <p> Some future changes are happening to this site, you need a passcode to view developmental changes</p>
            <Form.Item name="password" rules={[{ required: false, message: "Please input your password!" }]}>
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default PassCodeCheck;
