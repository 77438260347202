import React, { Component } from "react";
import { Line } from "@ant-design/plots";
import { Collapse, Col, Row } from "antd";
import LatencyChart from "./latency/LatencyChartComponent";
import BandwidthChart from "./bandwidth/BandwidthChartComponent";

const CHART_HEIGHT = 300;
const CHART_LABEL_FONT_SIZE = 10;
const { Panel } = Collapse;

class Diagrams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialTimestamp: 0,
      bufferLevel: [],
      bitrate: [],
      bitrateTimeBar: [],
    };

    // references
    this.latencyChart = React.createRef();
    this.bandwidthChart = React.createRef();
  }

  updateDiagrams = (object) => {
    var currentTimeDiff = 0;
    const now = Date.now();
    //today.setMilliseconds(0);

    if (this.state.initialTimestamp === 0) {
      this.setState({ initialTimestamp: now });
      currentTimeDiff = 0;
    } else {
      currentTimeDiff = Math.floor((now - this.state.initialTimestamp) / 1000);
    }

    //console.log("diagram time: " + currentTimeDiff);

    const bitrate = this.getNewBitrate(currentTimeDiff, object.bitrate);
    const bufferLevel = this.getNewBufferLevel(currentTimeDiff, object.videoBuffer, object.audioBuffer);

    if (object.latency !== null) {
      // add new latency line
      this.latencyChart.update(currentTimeDiff, object.latency);
    }

    //  and update the state with new arrays.
    this.setState({ bitrate: bitrate, bufferLevel: bufferLevel });
  };

  updateBandwidth = (object) => {
    if (typeof this.bandwidthChart.update === "function") {
      this.bandwidthChart.update(object);
    }
  };

  resetDiagrams = () => {
    if (typeof this.latencyChart.reset === "function") {
      this.latencyChart.reset();
    }
    if (typeof this.bandwidthChart.reset === "function") {
      this.bandwidthChart.reset();
    }
    this.setState({ initialTimestamp: 0, bufferLevel: [], bitrate: [] });
  };

  getNewBitrate = (time, bitrate) => {
    const array = this.state.bitrate;
    const bit = bitrate / 1000000;

    if (array.length === 100) {
      // remove first item
      array.shift();
    }
    // add new points to the array.
    array.push({ time: time, Mbps: bit, key: "Video" });
    return array;
  };

  getNewBufferLevel = (time, video, audio) => {
    const array = this.state.bufferLevel;
    if (array.length === 200) {
      // remove first two items
      array.shift();
      array.shift();
    }
    // add new points to the array.
    array.push({ time: time, key: "video", sec: video }, { time: time, key: "audio", sec: audio });
    return array;
  };

  getBufferLevelChart = () => {
    if (this.state.bufferLevel.length === 0) {
      return "";
    }

    return (
      <Line
        height={CHART_HEIGHT}
        data={this.state.bufferLevel}
        xField="time"
        yField="sec"
        seriesField="key"
        stepType="hvh"
        point={{ size: 5, shape: "diamond" }}
        responsive="true"
        autoFit="true"
        legend={{ position: "top" }}
        zoom={{ position: "topright" }}
        slider={{
          start: 0.6,
          end: 1,
          height: 5,
        }}
        xAxis={{
          tickCount: 5,
          title: {
            text: "Time",
            style: {
              fontSize: CHART_LABEL_FONT_SIZE,
            },
          },
        }}
        yAxis={{
          tickCount: 5,
          title: {
            text: "Sec",
            style: {
              fontSize: CHART_LABEL_FONT_SIZE,
            },
          },
        }}
      />
    );
  };

  getBitrateChart = () => {
    return (
      <Line
        height={CHART_HEIGHT}
        data={this.state.bitrate}
        padding="auto"
        xField="time"
        yField="Mbps"
        seriesField="key"
        stepType="hvh"
        legend={{ position: "top" }}
        zoom={{ position: "topright" }}
        autoFit="true"
        slider={{
          start: 0.6,
          end: 1,
          height: 5,
        }}
        xAxis={{
          tickCount: 5,
          title: {
            text: "Time",
            style: {
              fontSize: CHART_LABEL_FONT_SIZE,
            },
          },
        }}
        yAxis={{
          tickCount: 5,
          title: {
            text: "Mbps",
            style: {
              fontSize: CHART_LABEL_FONT_SIZE,
            },
          },
        }}
        point={{ size: 5, shape: "diamond" }}
      />
    );
  };

  render() {
    if (this.state.bitrate.length === 0) {
      return "";
    }

    return (
      <div>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12}>
            <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
              <Panel header="Buffer Levels" key="1" className="site-collapse-custom-panel">
                {this.getBufferLevelChart()}
              </Panel>
            </Collapse>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
              <Panel header="Bitrate" key="1" className="site-collapse-custom-panel">
                {this.getBitrateChart()}
              </Panel>
            </Collapse>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <BandwidthChart
              height={CHART_HEIGHT}
              labelFontSize={CHART_LABEL_FONT_SIZE}
              ref={(instance) => {
                this.bandwidthChart = instance;
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <LatencyChart
              height={CHART_HEIGHT}
              labelFontSize={CHART_LABEL_FONT_SIZE}
              ref={(instance) => {
                this.latencyChart = instance;
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Diagrams;
