import React, { Component } from "react";
import { Collapse, Slider, Badge } from "antd";
import type { SliderMarks } from "antd/es/slider";

const { Panel } = Collapse;

class BitrateSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableVideoQualities: [],
      currentBitrate: 0,
      suggestedBitrateId: 0,
    };
  }

  resetSlider = () => {
    this.setState({
      availableVideoQualities: [],
      currentBitrate: 0,
      suggestedBitrateId: 0,
    });
  };

  updateBitrateSlider = (object) => {
    this.setState({
      availableVideoQualities: object.availableVideoQualities,
      currentBitrate: object.currentBitrate,
      suggestedBitrateId: object.suggestedBitrateId,
    });
  };

  onBitrateSliderChange = (range) => {
    const arr = [];

    for (let item of this.state.availableVideoQualities) {
      if (arr.length === 0) {
        // check min value
        if (item.bandwidth / 1000000 === range[0]) {
          arr.push(item.id);

          // that means both
          if (range[0] === range[1]) {
            break;
          }
        }
      } else {
        // add new id
        arr.push(item.id);

        if (item.bandwidth / 1000000 === range[1]) {
          break;
        }
      }
    }

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "BitrateSliderChange",
      time: Date.now(),
      text: "Bitrate Slider has been changed",
      detail: { Bitrates: range, Qualities: arr},
    });

    this.props.changeBitrateRange(arr);
  };

  renderVideoBitrateSlider = () => {
    if (this.state.availableVideoQualities.length === 0) {
      // don't show anything
      return "";
    }

    // create marks
    const marks: SliderMarks = {};
    this.state.availableVideoQualities.forEach((item, index) => {
      const mbps = item.bandwidth / 1000000;

      //#ccc987

      // show current bitrate differently
      if (this.state.currentBitrate / 1000000 === mbps) {
        marks[mbps] = {
          style: {
            fontSize: 14,
            color: "#f50",
          },
          label: <strong>{mbps.toFixed(2)}</strong>,
        };
      } else if (item.id === this.state.suggestedBitrateId) {
        marks[mbps] = {
          style: {
            fontSize: 14,
            color: "#87d068",
          },
          label: <strong>{mbps.toFixed(2)}</strong>,
        };
      } else {
        marks[mbps] = {
          style: {
            fontSize: 12,
          },
          label: mbps.toFixed(2),
        };
      }
    });

    const tooltipFormatter = (value: number) => `${value} Mbps`;

    const min = this.state.availableVideoQualities[0].bandwidth / 1000000;
    const max = this.state.availableVideoQualities[this.state.availableVideoQualities.length - 1].bandwidth / 1000000;
    return (
      <div>
        <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
          <Panel header="Limit Bitrate" key="1" className="site-collapse-custom-panel">
            <Badge color="#87d068" text="Bitrate suggested by the player" style={{ fontWeight: 1 }} />
            <br />
            <Badge color="#f50" text="Current Bitrate" style={{ fontWeight: 1 }} />
            <br />
            <Slider
              min={min}
              max={max}
              range
              marks={marks}
              step={null}
              tipFormatter={tooltipFormatter}
              defaultValue={[min, max]}
              onChange={this.onBitrateSliderChange}
            />
          </Panel>
        </Collapse>
        <br />
      </div>
    );
  };

  render() {
    return <div>{this.renderVideoBitrateSlider()}</div>;
  }
}

export default BitrateSlider;
