import React, { Component } from "react";
import { Col, Row, Space, Typography } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { getSupportedMediaSources } from "./SupportedStatsHelper";

const { Text } = Typography;

class SupportedStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportedDRMSystems: [],
      supportedTech: [],
    };
  }

  setSupportedDRMSystems = (object) => {
    this.setState({
      supportedDRMSystems: object.supportedDRMSystems,
      supportedTech: object.supportedTech,
    });
  };

  getSupportedDRMSystems = () => {
    return this.state.supportedDRMSystems;
  };

  renderSupportedStats = (title, array, withIcon) => {
    if (array.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <Space direction="vertical">
        <Text type="secondary">{title}</Text>
        <div
          id="scrollableDiv"
          style={{
            height: 90,
            overflow: "auto",
          }}
        >
          {array.map((item) => (
            <div
              style={{
                marginRight: 10,
              }}
            >
              {withIcon ? (
                Object.values(item)[0] === false ? (
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ) : (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )
              ) : (
                ""
              )}{" "}
              {Object.keys(item)[0]}
            </div>
          ))}
        </div>
      </Space>
    );
  };

  renderSupportedTechs = (title, array) => {
    if (array.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <Space direction="vertical">
        <Text type="secondary">{title}</Text>
        <div
          id="scrollableDiv"
          style={{
            height: 90,
            overflow: "auto",
          }}
        >
          {array.map((item) => (
            <div
              style={{
                marginRight: 10,
              }}
            >
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              {" " + Object.values(item)[0] + " - " + Object.values(item)[1]}
            </div>
          ))}
        </div>
      </Space>
    );
  };

  render() {
    let halfwayThrough = Math.floor(getSupportedMediaSources().length / 2);

    let arrayFirstHalf = getSupportedMediaSources().slice(0, halfwayThrough);
    let arraySecondHalf = getSupportedMediaSources().slice(halfwayThrough, getSupportedMediaSources().length);

    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={6}>
            {this.renderSupportedStats("Supported DRM Systems", this.state.supportedDRMSystems, true)}
          </Col>
          <Col xs={12} sm={12} md={6}>
            {this.renderSupportedTechs("Supported Tech", this.state.supportedTech)}
          </Col>
          <Col xs={24} sm={12} md={6}>
            {this.renderSupportedStats("Supported Media Sources", arrayFirstHalf, true)}
          </Col>
          <Col xs={24} sm={12} md={6}>
            {this.renderSupportedStats("Supported Media Sources", arraySecondHalf, true)}
          </Col>
        </Row>
      </div>
    );
  }
}

export default SupportedStats;
