import React, { Component } from "react";
import { Form, Button, Checkbox, Select, message, Tag } from "antd";
import { setCookie, expireCookie } from "../../../utils/cookie";
import { stopOldPlaybackSession } from "../../../utils/PlaybackSessionManager";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorages,
  LOCAL_STORAGE_KEYS,
} from "../../../utils/LocalStorageManager";
import { getLoginURL, perform_login, perform_logout, sessionKeepAlive, saveLocalStorages } from "./LoginHelper";
import Constants from "../../../constants/Constants";
import { isProd, getDeviceType } from "../../../utils/Utility";

const { AVS_CONFIG } = Constants;

const ENVIRONMENTS = [
  { label: "Prod", value: "prod" },
  { label: "Dev", value: "dev" },
  { label: "Sit", value: "sit" },
  { label: "Preprod", value: "preprod" },
];

class LoginTab extends Component {
  isLoading = false;

  componentDidMount() {
    const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
    const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);
    const isLoggedIn = getLocalStorage(LOCAL_STORAGE_KEYS.IS_LOGGED_IN);

    console.log(
      "[DEBUG]:componentDidMount: avs_refresh_token:  " +
        avs_refresh_token +
        " isLoggedIn: " +
        isLoggedIn +
        " environment " +
        environment
    );

    if (environment) {
      this.checkAuthorizationCode(environment);
    }
  }

  onLogin = (values) => {
    // clear all local storages.
    clearLocalStorages();

    // store user selections.
    setLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT, values.environment);
    setLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_REMEMBER, values.remember);

    // Redirect site to SignInURL for Auto Login or ReLogin Session
    window.location.href = getLoginURL(values.environment);
  };

  onLogout = async () => {
    // if there is an playback session, kill it
    stopOldPlaybackSession();

    const logoutResult = await perform_logout();
    if (logoutResult.result) {
      expireCookie();
      clearLocalStorages();

      window.location.href = isProd() ? AVS_CONFIG.REDIRECT_URL : AVS_CONFIG.DEV_REDIRECT_URL;
    } else {
      message.error(logoutResult.error);
    }
  };

  login = async (code, state, environment) => {
    // perform login
    const loginResult = await perform_login({ code: code, state: state }, environment);
    if (loginResult.result) {
      //console.log(" Login action result is : " + JSON.stringify(loginResult));

      saveLocalStorages(loginResult);

      if (getLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_REMEMBER)) {
        setCookie("avs-refresh-token", loginResult.avs_refresh_token, 365);
      }

      // keep session alive
      sessionKeepAlive(loginResult.avs_refresh_token, environment).then((response) => {
        if (response.result) {
          // TODO: use keepAlive value..
        } else {
          // show up the error
          message.error(response.error);
        }
      });

      window.location.href = isProd() ? AVS_CONFIG.REDIRECT_URL : AVS_CONFIG.DEV_REDIRECT_URL + "#/";
    } else {
      // display error message on screen.
      message.error(loginResult.error);
    }

    this.isLoading = false;
  };

  checkAuthorizationCode = async (environment) => {
    // console.log('Failed:', errorInfo);
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    const isLoggedIn = getLocalStorage(LOCAL_STORAGE_KEYS.IS_LOGGED_IN);

    if (code != null && state != null && isLoggedIn === null && !this.isLoading) {
      // do not let run multiple call for login.
      this.isLoading = true;
      this.login(code, state, environment);
    }
  };

  getLoginForm = () => {
    return (
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={this.onLogin}
        autoComplete="off"
        style={{ width: "100%", alignItems: "center" }}
      >
        <Form.Item name="environment" rules={[{ required: true, message: "Please select an environment!" }]}>
          <Select placeholder="Select an Environment" options={ENVIRONMENTS} onChange={this.onContentTypeChange} />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    );
  };

  getLogoutForm = () => {
    return (
      <Form
        name="logout"
        initialValues={{ remember: true }}
        onFinish={this.onLogout}
        autoComplete="off"
        style={{ width: "60%", alignItems: "center" }}
      >
        <p>
          Environment: <Tag color="orange">{getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT)}</Tag>
        </p>
        <p>
          Device Type: <Tag color="green">{getDeviceType()}</Tag>
        </p>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Logout
          </Button>
        </Form.Item>
      </Form>
    );
  };

  render() {
    const isLoggedIn = getLocalStorage(LOCAL_STORAGE_KEYS.IS_LOGGED_IN);
    if (isLoggedIn) {
      return this.getLogoutForm();
    } else {
      return this.getLoginForm();
    }
  }
}

export default LoginTab;
