import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getAuthBaseUrl, getAvsBaseUrl, isProd, getDeviceType } from "../../../utils/Utility";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";
import URLConstants from "../../../constants/URLs";
import Constants from "../../../constants/Constants";

const { AVS_API_URLS } = URLConstants;
const { AVS_CONFIG } = Constants;

//"https://oauth.cto.tv.telus.net/as/authorization.oauth2?response_type=code&scope=opuscisso&client_id=optiktv_web_client&state=5b2b82fd-da63-4bad-9a25-f7e218e51584&redirect_uri=http%3A%2F%2Flocalhost"
// https://oauth.tv.telus.net/as/authorization.oauth2?response_type=code&scope=opuscisso&client_id=opus&state=6277c0db-d9e4-4e2b-9d06-9a23926c75e7&redirect_uri=http%3A%2F%2Flocalhost
export const getLoginURL = (environment) => {
  const state = uuidv4();
  setLocalStorage(LOCAL_STORAGE_KEYS.DEVICE_ID, state);

  let url = getAuthBaseUrl(environment) + AVS_API_URLS.SIGN_IN_URL;

  url = url.replace("$CLIENT_ID", AVS_CONFIG.CLIENT_ID);
  url = url.replace("$STATE", state);
  url = url.replace("$REDIRECT_URL", isProd() ? AVS_CONFIG.REDIRECT_URL : AVS_CONFIG.DEV_REDIRECT_URL);

  return url;
};

export const saveLocalStorages = (loginResult) => {
  setLocalStorage(LOCAL_STORAGE_KEYS.IS_LOGGED_IN, loginResult.result);
  setLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN, loginResult.avs_refresh_token);
  setLocalStorage(LOCAL_STORAGE_KEYS.REGION_ID, loginResult.region_id);
  setLocalStorage(LOCAL_STORAGE_KEYS.LIVE_PACKAGE_IDS, parsePackageList(loginResult.package_list));
  console.log("clearLocalStorages has been called.");
};

const parsePackageList = (package_list) => {
  const packageIds = [];
  package_list.forEach((item) => {
    if (item.packageType === "VOD_LIVE_SUB") {
      packageIds.push(item.packageId);
    }
  });

  return packageIds.join();
};

export const perform_login = async (login_response_params, environment) => {
  let url = getAvsBaseUrl(environment) + AVS_API_URLS.LOGIN;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());

  // {"url":"http://localhost:8010/proxy/TELUS/1.5/A/ENG/CHROME_FIREFOX_HTML5/ALL/USER/SESSIONS","method":"POST","headers":{"Content-Type":"application/json"},"data":{"credentialsExtAuth":{"deviceInfo":{"deviceId":"347e985c-6901-490f-8ba6-89cebda4c03c","deviceIdType":"DEVICEID","deviceType":"CHROME_FIREFOX_HTML5"},"credentials":{"client_id":"optiktv_web_client","secret":"lvZnGh-17RuvdGy1lQ5X3WQX0QwWAky4-nUAAADI","state":"347e985c-6901-490f-8ba6-89cebda4c03c","redirect_uri":"http://localhost"}}},"withCredentials":true,"returnResponseObject":true}
  const result = await axios({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      credentialsExtAuth: {
        deviceInfo: {
          deviceId: login_response_params.state,
          deviceIdType: AVS_CONFIG.DEVICE_ID_TYPE, //"DEVICEID"
          deviceType: getDeviceType(), //"CHROME_FIREFOX_HTML5", "ANDROID_TV_STB"
        },
        credentials: {
          client_id: AVS_CONFIG.CLIENT_ID, // "optiktv_web_client", "opus",
          secret: login_response_params.code,
          state: login_response_params.state,
          redirect_uri: isProd() ? AVS_CONFIG.REDIRECT_URL : AVS_CONFIG.DEV_REDIRECT_URL, //"http://localhost"
        },
      },
    },
    withCredentials: true,
    returnResponseObject: true,
    exposedHeaders: ["set-cookie"],
  })
    .then((response) => {
      console.log("perform_login result: " + JSON.stringify(response));

      if (response.data.resultCode === "OK") {
        const avs_refresh_token = response.headers["avs-refresh-token"];
        const region_id = response.data.resultObj.profile.profileData.regionId;
        const package_list = response.data.resultObj.profile.profileData.packageList;

        // add token variable but no need to store it.
        // caching the playing content for stats panel.
        setLocalStorage(LOCAL_STORAGE_KEYS.LAST_TOKEN, { token: avs_refresh_token, time: Date.now() });
        return {
          result: true,
          avs_refresh_token: avs_refresh_token,
          region_id: region_id,
          package_list: package_list,
        };
      } else if (response.data.resultCode === "KO") {
        return {
          result: false,
          error: response.data.message,
        };
      }
      return {
        result: false,
        error: "Failed to login",
      };
    })
    .catch((error) => {
      console.log("[DEBUG] Login action has been failed: " + JSON.stringify(error));

      return {
        result: false,
        error: error,
      };
    });

  return result;
};

export const perform_logout = async () => {
  const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
  const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);

  if (avs_refresh_token === null) {
    return { result: false, error: "Avs refresh token is null." };
  }

  let url = getAvsBaseUrl(environment) + AVS_API_URLS.LOGOUT;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());

  //sample:"http://localhost:8010/proxy/TELUS/1.5/A/ENG/CHROME_FIREFOX_HTML5/ALL/USER/SESSIONS","method":"POST","headers":{"Content-Type":"application/json"},"data":{"credentialsExtAuth":{"deviceInfo":{"deviceId":"347e985c-6901-490f-8ba6-89cebda4c03c","deviceIdType":"DEVICEID","deviceType":"CHROME_FIREFOX_HTML5"},"credentials":{"client_id":"optiktv_web_client","secret":"lvZnGh-17RuvdGy1lQ5X3WQX0QwWAky4-nUAAADI","state":"347e985c-6901-490f-8ba6-89cebda4c03c","redirect_uri":"http://localhost"}}},"withCredentials":true,"returnResponseObject":true}
  const result = await axios({
    url: url,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Avs-Refresh-Token": avs_refresh_token,
    },
    data: {
      logoutDevice: {},
    },
    withCredentials: true,
  })
    .then((response) => {
      if (response.data.resultCode === "OK") {
        return { result: true };
      } else if (response.data.resultCode === "KO") {
        return { result: false, error: response.data.message };
      }

      return { result: false, error: "Failed to perform logout." };
    })
    .catch((error) => {
      console.log(" Logout action has been failed: " + JSON.stringify(error));

      return { result: false, error: error };
    });

  return result;
};

export const sessionKeepAlive = async (avs_refresh_token, environment) => {
  let url = getAvsBaseUrl(environment) + AVS_API_URLS.USER_SESSION_RENEWAL;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());
  url = url.replace("$PROPERTY", AVS_CONFIG.PROPERTY_NAME);

  // sample: "url":"http://localhost:8010/proxy/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/USER/DEVICESESSIONS","method":"POST","headers":{"Content-Type":"application/json","avs-refresh-token":"eyJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjoiNDk2cTJYNStrcUlZdTRad29DUnNyL1lmbWFpWHVsQUkrb1Q1amRCWXJ0UWdXWFFvM1dzb1psaTlGOWxPVjJhSk1vV1crRUFFd2pteEhISGVLTWRUaGNqa3NzdGxsK1Z5b0JadS9GL3pMOHd6TnpQWGo0VEcwNUZDQ2tZQmdhNjhQYzFsMFd1WjBHZ2RleWQ1Y3Y1RFJPK1o3ZFF1QWc9PSIsImlzcyI6IkFWUyIsImV4cCI6MTY4NTE5MzQwNiwibm9uY2UiOiJbQkAzZDQ5ZThiNyJ9.Tq0ua5_LTnpTHgi0GqrgkQgVOraAEuIARdJqCgMpc5M"},"data":{},"withCredentials":true,"returnResponseObject":true}
  const result = await axios({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "avs-refresh-token": avs_refresh_token,
    },
    data: {},
    withCredentials: true,
    returnResponseObject: true,
    json: true,
  })
    .then((response) => {
      // console.log("response: " + JSON.stringify(response));
      if (response.data.resultCode === "OK") {
        return { result: true, keepAlive: response.data.resultObj.keepAlive };
      } else if (response.data.resultCode === "KO") {
        return { result: false, error: response.data.message };
      }

      return { result: false, error: "Failed to session keep alive." };
    })
    .catch((error) => {
      console.log(" sessionKeepAlive action has been failed: " + JSON.stringify(error));

      return { result: false, error: error };
    });

  return result;
};
