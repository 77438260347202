import ListTab from "../ListTabComponent";
import { generatePlayerSource } from "./DemoListHelper";
import { stopOldPlaybackSession } from "../../../utils/PlaybackSessionManager";
import { CONTENTS } from "../../../constants/DemoContents";

class DemoListTab extends ListTab {
  /**
   * Override onClick of the ListTab function to set the source of the list component.
   */
  onClick = async (index) => {
    console.log("onClick: " + index + " tabs: " + this.props.tab);
    const content = CONTENTS[index];

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "DemoListOnClick",
      time: Date.now(),
      text: "onClick action has performed for DemoList - index: " + index,
      detail: null,
    });

    const source = await generatePlayerSource(content, this.props.updateTimeline);

    if (source !== null) {
      this.props.onContentSelected({ index: index, tab: this.props.tab }, source);

      // if there is an playback session, kill it
      stopOldPlaybackSession(this.props.updateTimeline);
    }
  };

  /**
   * Override createListSource of the ListTab function to set the source of the list component.
   */
  createListSource = () => {
    // create an array for tree data.
    var array = [];

    // loop contents to get desired types
    CONTENTS.forEach((content, index) => {
      if (this.props.isClear === true && content.license_url === undefined) {
        content.index = index;
        content.description = content.url;
        // add content
        array.push(content);
      } else if (this.props.isClear === false && content.license_url !== undefined) {
        content.index = index;
        content.description = content.url;

        // add content
        array.push(content);
      }
    });

    return array;
  };
}

export default DemoListTab;
