export default Object.freeze({
  AVS_CONFIG: {
    CLIENT_ID: "optiktv_web_client", // opus
    DEV_REDIRECT_URL: "http://localhost",
    REDIRECT_URL: "https://watchtelustv.com",
    DEVICE_ID_TYPE: "DEVICEID",
    APP_VERSION: "T2.0",
    PROPERTY_NAME: "OPTIK",
    LANGUAGE: "ENG",
  },
  DEVICE_TYPES: {
    SAFARI: "SAFARI_HTML5",
    CHROME: "CHROME_FIREFOX_HTML5",
    FIREFOX: "CHROME_FIREFOX_HTML5",
    EDGE: "EDGE_HTML5",
    ANDROID_TV: "ANDROID_TV_STB",
    DEFAULT: "CHROME_FIREFOX_HTML5",
  },
  PLAYER_TYPE: {
    LINEAR: "linear",
    LIVE: "live",
    VOD: "vod",
    RECORDING: "recording",
  },
  STREAM_MODE: {
    LIVE: "IMC_MODE_LIVE",
    VOD: "IMC_MODE_ADAPTIVE",
  },
  PLAYER_LOG_LEVELS: {
    ERROR: "IMC_LOG_ERROR",
    INFO: "IMC_LOG_INFO",
    DEBUG: "IMC_LOG_DEBUG",
    WARN: "IMC_LOG_WARN",
    NONE: "IMC_LOG_NONE",
  },
  PLAYBACK_TYPES: {
    MOVIE: "movie",
    TRAILER: "trailer",
    EPISODE: "episode",
  },
  SUPPORTED_PLAYBACK_ENCODING: ["Jitp", "hls"],
  PLAYER_CONTROLS: {
    STOP: "stop",
    PLAY: "play",
    RESUME: "resume",
    PAUSE: "pause",
    GET_NEXT_LIVE_PROGRAM: "GET_NEXT_LIVE_PROGRAM",
    PLAY_COMPLETED: "PLAY_COMPLETED",
    VOLUME: "volume",
    MUTE: "mute",
    CLOSE: "close",
  },
  PLAYER_STREAM_CONFIG: {
    live: {
      streamURL: {
        hls: "https://vxoalottdevopus.cdn.avp.teluslabs.net/$CONTENTID/vxfmt=dp/playlist.m3u8?device_profile=hlsvmxfps",
        dash: "https://vxoalottdevopus.cdn.avp.teluslabs.net/$CONTENTID/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
      },
      licenseURL: {
        hls: "https://multidrm.core.verimatrixcloud.net/fairplay",
        dash: "https://multidrm.core.verimatrixcloud.net/widevine",
      },
      licenseCertUrl: {
        hls: "../../libs/fairplay.cer",
      },
    },
    vod: {
      streamURL: {
        hls: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/shls/$CONTENTID/index.m3u8?device=VMX_CORE_HLS",
        dash: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/$CONTENTID/index.mpd/Manifest?device=VMX_CORE_DASH",
      },
      licenseURL: {
        hls: "https://multidrm.core.verimatrixcloud.net/fairplay",
        dash: "https://multidrm.core.verimatrixcloud.net/widevine",
      },
      licenseCertUrl: {
        hls: "../../libs/fairplay.cer",
      },
    },
  },
  STREAM_FORMAT_TYPES: {
    HLS: "hls",
    DASH: "dash",
  },
  DRM_SYSTEM_TYPES: {
    WV: "widevine",
    PR: "playready",
    FP: "fairplay",
  },
  ASSET_TYPES: {
    TRAILER: "trailer",
    MASTER: "master",
  },
  PLAYER_TOOLTIPS: {
    getDroppedVideoFrames: "Shows the total number of dropped video frames since playback started.",
  },
});
