import URLConstants from "../constants/URLs";
import Constants from "../constants/Constants";

const { AUTH_BASE_URLS, AVS_BASE_URLS } = URLConstants;
const { PLAYER_STREAM_CONFIG, DEVICE_TYPES } = Constants;

export const getAuthBaseUrl = (environment) => {
  switch (environment) {
    case "prod":
      return AUTH_BASE_URLS.PROD;
    case "dev":
      return AUTH_BASE_URLS.DEV;
    case "sit":
      return AUTH_BASE_URLS.SIT;
    case "preprod":
      return AUTH_BASE_URLS.PRE_PRO;
    default:
      return null;
  }
};

export const getAvsBaseUrl = (environment) => {
  if (!isProd()) {
    return AVS_BASE_URLS.LOCAL;
  }
  switch (environment) {
    case "prod":
      return AVS_BASE_URLS.PROD;
    case "dev":
      return AVS_BASE_URLS.DEV;
    case "sit":
      return AVS_BASE_URLS.SIT;
    case "preprod":
      return AVS_BASE_URLS.PRE_PRO;
    default:
      return null;
  }
};

export const getDeviceType = () => {
  const browser = getBrowserName().toUpperCase();
  return DEVICE_TYPES[browser];
};

/**
 * Converts a content to the object that the player expects
 */
export const contentToPlayerSource = (content) => {
  //console.log("contentToPlayerSource : " + JSON.stringify(content));
  if (IsSafari()) {
    return {
      hls: content.url,
      drm: {
        fairplay: {
          LA_URL: PLAYER_STREAM_CONFIG.live.licenseURL.hls,
          certificateURL: PLAYER_STREAM_CONFIG.live.licenseCertUrl.hls,
          headers: {
            authorization: content.token,
            "Content-Type": "application/json",
          },
          withCredentials: true,
          useUint16InitData: true,
          prepareContentId: (contentId) => {
            return contentId.slice(8);
          },
          prepareMessage: (event, session) => {
            return JSON.stringify({
              spc: event.messageBase64Encoded,
            });
          },
          prepareLicense: (data) => {
            let parsed = JSON.parse(data);
            return parsed.ckc;
          },
        },
      },
    };
  }

  return {
    dash: content.url,
    drm: {
      widevine: {
        LA_URL: PLAYER_STREAM_CONFIG.live.licenseURL.dash,
        headers: {
          authorization: content.token,
        },
        withCredentials: true,
      },
    },
  };
};

/**
 * Checks out the environment of the project whether it's prod or not.
 * For example, when it's run on Localhost, it returns false.
 */
export const isProd = () => {
  return process.env.NODE_ENV === "production";
};

/**
 * Detect browser type safari
 */
export function IsSafari() {
  let isSafariBrowser;
  const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  isSafariBrowser =
    userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i)[1] === "Safari" ? true : false;

  return isSafariBrowser;
}

/**
 * Returns the name of the browser.
 * TODO: This method should fetch the browser name using the duck type methodology.
 * @return String Browser Name
 */
export function getBrowserName() {
  const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

  const browser = userAgent.match(/(opera|chrome|safari|firefox|msie|jsdom)\/?\s*(\.?\d+(\.\d+)*)/i)[1];

  if (browser === "Chrome" && userAgent.indexOf("Edg") !== -1) return "edge";

  return browser.toLowerCase();
}