import React, { Component } from "react";
import { Line } from "@ant-design/plots";
import { Collapse, Col } from "antd";

const { Panel } = Collapse;

class LatencyChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latencyArr: [],
    };
  }

  update = (currentTimeDiff, newLatency) => {
    const latency = this.getNewLatencyArr(currentTimeDiff, newLatency);

    //  and update the state with new arrays.
    this.setState({ latencyArr: latency });
  };

  reset = () => {
    this.setState({ latencyArr: [] });
  };

  getNewLatencyArr = (time, object) => {
    if (object.currentLatency === Infinity) {
      return null;
    }

    const array = this.state.latencyArr === null ? [] : this.state.latencyArr;
    const latency = Math.round(object.currentLatency * 1000) / 1000;

    if (array.length === 100) {
      // remove first two items
      array.shift();
      array.shift();
    }

    // add new points to the array.
    array.push({ time: time, key: "Target", sec: object.targetLatency }, { time: time, key: "Current", sec: latency });

    return array;
  };

  render() {
    if (this.state.latencyArr === null || this.state.latencyArr.length === 0) {
      return "";
    }

    return (
      // <Col sm={24} flex={12}>
      <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
        <Panel header="Latency" key="1" className="site-collapse-custom-panel">
          <Line
            height={this.props.height}
            data={this.state.latencyArr}
            padding="auto"
            xField="time"
            yField="sec"
            seriesField="key"
            stepType="hvh"
            legend={{ position: "topleft" }}
            zoom={{ position: "topright" }}
            // color="#eed056"
            autoFit="true"
            slider={{
              start: 0.6,
              end: 1,
              height: 5,
            }}
            xAxis={{
              tickCount: 5,
              title: {
                text: "Time",
                style: {
                  fontSize: this.props.labelFontSize,
                },
              },
            }}
            yAxis={{
              tickCount: 5,
              title: {
                text: "Sec",
                style: {
                  fontSize: this.props.labelFontSize,
                },
              },
            }}
            point={{ size: 5, shape: "diamond" }}
          />
        </Panel>
      </Collapse>
      // </Col>
    );
  }
}

export default LatencyChart;
