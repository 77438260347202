/**
 * Returns the value of the given cookie name
 * @param {String} cookieName
 * @return {String|null}
 */
 export function getCookie(cookieName) {
    const name = cookieName + "=";
    const cookieArray = document.cookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  }
  
  /**
   * Sets the value of a cookie with given cookie name, value and expiry days
   * @param {String} name
   * @param {String} value
   * @param {Number} expiryDays
   */
  export function setCookie(name, value, expiryDays) {
    const d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  
  /**
   * Expires the cookie with given cookie name
   * @param {String} name
   */
  export function expireCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  