export default Object.freeze({
  AUTH_BASE_URLS: {
    PROD: "https://oauth.tv.telus.net",
    PRE_PRO: "https://oauth.cto.tv.telus.net",
    SIT: "https://oauth.cto.tv.telus.net",
    DEV: "https://oauth.devrep.tv.telus.net",
  },
  AVS_BASE_URLS: {
    PROD: "https://telus.prod.g.svc.tv.telus.net/",
    PRE_PRO: "https://telus.preprod.n.svc.tv.telus.net/",
    SIT: "https://telus.sit.n.svc.tv.telus.net/",
    DEV: "https://telus.dev.n.svc.tv.telus.net/",
    LOCAL: "https://localhost:8010/proxy/",
  },
  AVS_API_URLS: {
    SIGN_IN_URL: "/as/authorization.oauth2?response_type=code&scope=opuscisso&client_id=$CLIENT_ID&state=$STATE&redirect_uri=$REDIRECT_URL",
    USER_SESSION_RENEWAL: "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/$PROPERTY/USER/DEVICESESSIONS",
    LOGIN: "TELUS/$AGL_VERSION/A/$LANG/$PLATFORM/ALL/USER/SESSIONS",
    LOGOUT:  "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/ALL/USER/SESSIONS",
    VOD: "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/$PROPERTY/TRAY/SEARCH/VOD?query=$KEYWORD&from=$FROM&to=$TO&maxResults=$MAX_RESULTS&filter_regionId=$REGION_ID",
    CHANNEL: "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/$PROPERTY/TRAY/LIVECHANNELS?orderBy=$ORDER_BY&sortOrder=$SORT_ORDER&filter_technicalPackages=$LIVE_PACKAGE_IDS&regionId=$REGION_ID",
    VIDEO_URL: "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/$PROPERTY/CONTENT/VIDEOURL/$CONTENT_TYPE/$CONTENT_ID/$ASSET_ID?onlyToken=$IS_ONLY_TOKEN&deviceId=$DEVICE_ID",
    STOP_PLACKBACK_SESSION: "TELUS/$AGL_VERSION/R/$LANG/$PLATFORM/$PROPERTY/CONTENT/USERDATA/$CONTENT_TYPE/$CONTENT_ID"
  },
  LAB_TOKEN_GENERATOR : "https://tokengen.telus-lab.core.verimatrixcloud.net/token/?expiresIn=90&subject=",
});