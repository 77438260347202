import React, { Component } from "react";
import { Collapse, Slider, Badge, Tooltip, Row, Col, Divider } from "antd";
import type { SliderMarks } from "antd/es/slider";

const { Panel } = Collapse;
const LATENCY_RANGE = [0.5, 6];
const DEFAULT_TARGET_LATENCY = 3;
const CONFIG_RANGE = [0, 10];

class LatencySlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLatency: 0,
      targetLatency: DEFAULT_TARGET_LATENCY,
      time: new Date(),
      timeShift: 0,
      catchupConfig: {
        playbackRate: 1.2,
        playbackRateThreshold: 0.075,
        seekThreshold: 5,
      },
      fallbackConfig: {
        playbackRate: 0.95,
        playbackRateThreshold: 0.075,
        seekThreshold: 5,
      },
    };
  }

  componentDidMount() {
    // create the interval once component is mounted
    this.update = setInterval(() => {
      this.setState({ time: new Date() });
    }, 1000);
  }

  componentWillUnmount() {
    // delete the interval just before component is removed
    clearInterval(this.update);
  }

  resetSlider = () => {
    this.setState({
      currentLatency: 0,
      targetLatency: DEFAULT_TARGET_LATENCY,
    });
  };

  updateLatencySlider = (object) => {
    // console.log("updateLatencySlider: " + JSON.stringify(object));

    this.setState({
      currentLatency: object.currentLatency,
      timeShift: object.timeShift,
    });
  };

  onLatencySliderChange = (object) => {
    this.setState({
      targetLatency: object,
    });

    // call player to update the target latency
    this.props.changeTargetLatency(object);

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "TargetLatencySliderChange",
      time: Date.now(),
      text: "Target latency slider has changed to " + object + " sec",
      detail: null,
    });
  };

  onConfigSliderChange = (value, slider, isFallback) => {
    console.log("onConfigSliderChange" + value + " slider: " + slider);
    var config = null;

    if (isFallback) {
      config = {
        playbackRate: slider === 1 ? value : this.state.fallbackConfig.playbackRate,
        playbackRateThreshold: slider === 2 ? value : this.state.fallbackConfig.playbackRateThreshold,
        seekThreshold: slider === 3 ? value : this.state.fallbackConfig.seekThreshold,
      };

      this.setState({ fallbackConfig: config });
    } else {
      config = {
        playbackRate: slider === 1 ? value : this.state.catchupConfig.playbackRate,
        playbackRateThreshold: slider === 2 ? value : this.state.catchupConfig.playbackRateThreshold,
        seekThreshold: slider === 3 ? value : this.state.catchupConfig.seekThreshold,
      };

      this.setState({ catchupConfig: config });
    }

    this.props.changeLowLatencyConfig(isFallback, config);

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "LatencyConfigSliderChange",
      time: Date.now(),
      text: `${
        slider === 1 ? "playbackRate" : slider === 2 ? "playbackRateThreshold" : "seekThreshold"
      } slider of the ${isFallback ? "Fallback" : "Catchup"} Low Latency config has changed to ${value}`,
      detail: null,
    });
  };

  renderLatencySlider = () => {
    if (this.state.currentLatency === 0 || this.state.currentLatency === Infinity) {
      // don't show anything
      return "";
    }

    // create marks
    const marks: SliderMarks = {};
    LATENCY_RANGE.forEach((item, index) => {
      marks[item] = {
        style: {
          fontSize: 12,
        },
        label: item,
      };
    });

    const tooltipFormatter = (value: number) => `${value} sec`;

    return (
      <div>
        <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
          <Panel header="Low Latency" key="1" className="site-collapse-custom-panel">
            <Badge
              color="green"
              text={"Local time: " + this.state.time.toLocaleTimeString()}
              style={{ fontWeight: 1 }}
            />
            <br />
            <Tooltip placement="right" title={"Shifts the time to the given offset in seconds from the live edge."}>
              <Badge color="green" text={"Time Shift: " + this.state.timeShift} style={{ fontWeight: 1 }} />
            </Tooltip>
            <br />
            <Badge color="green" text={"Current Latency: " + this.state.currentLatency} style={{ fontWeight: 1 }} />
            <br />
            <br />
            <Badge color="red" text={"Target Latency: " + this.state.targetLatency} style={{ fontWeight: 1 }} />
            <Slider
              min={LATENCY_RANGE[0]}
              max={LATENCY_RANGE[1]}
              marks={marks}
              tipFormatter={tooltipFormatter}
              defaultValue={this.state.targetLatency}
              onChange={this.onLatencySliderChange}
              value={this.state.targetLatency}
              step={0.01}
            />
            <br />
            <Divider orientation="left" plain>
              Advanced Options :
            </Divider>

            <Collapse ghost>
              {this.renderConfigPanel("Catchup Config", 1, false)}
              {this.renderConfigPanel("Fallback Config", 2, true)}
            </Collapse>
          </Panel>
        </Collapse>
        <br />
      </div>
    );
  };

  renderConfigPanel = (title, key, isFallback) => {
    // create marks
    const marks: SliderMarks = {};
    CONFIG_RANGE.forEach((item, index) => {
      marks[item] = {
        style: {
          fontSize: 12,
        },
        label: item,
      };
    });

    return (
      <Panel header={title} key={key}>
        <Row gutter={[20, 20]}>
          <Col sm={24} md={8}>
            <Tooltip placement="right" title={`Playback speed to be used for ${isFallback ? "fallback" : "catchup"}`}>
              <Badge
                color="yellow"
                text={`PlaybackRate:  ${
                  isFallback ? this.state.fallbackConfig.playbackRate : this.state.catchupConfig.playbackRate
                }`}
                style={{ fontWeight: 1 }}
              />
            </Tooltip>

            <Slider
              min={CONFIG_RANGE[0]}
              max={CONFIG_RANGE[1]}
              marks={marks}
              defaultValue={isFallback ? this.state.fallbackConfig.playbackRate : this.state.catchupConfig.playbackRate}
              value={isFallback ? this.state.fallbackConfig.playbackRate : this.state.catchupConfig.playbackRate}
              onChange={(value) => this.onConfigSliderChange(value, 1, isFallback)}
              step={0.01}
            />
          </Col>
          <Col sm={24} md={8}>
            <Tooltip
              placement="right"
              title={`Allowed deviation from target latency before ${
                isFallback ? "fallback" : "catchup"
              } by changing the playback speed is done.`}
            >
              <Badge
                color="orange"
                text={`PlaybackRateThreshold:  ${
                  isFallback
                    ? this.state.fallbackConfig.playbackRateThreshold
                    : this.state.catchupConfig.playbackRateThreshold
                }`}
                style={{ fontWeight: 1 }}
              />
            </Tooltip>
            <Slider
              min={CONFIG_RANGE[0]}
              max={CONFIG_RANGE[1]}
              marks={marks}
              defaultValue={
                isFallback
                  ? this.state.fallbackConfig.playbackRateThreshold
                  : this.state.catchupConfig.playbackRateThreshold
              }
              value={
                isFallback
                  ? this.state.fallbackConfig.playbackRateThreshold
                  : this.state.catchupConfig.playbackRateThreshold
              }
              onChange={(value) => this.onConfigSliderChange(value, 2, isFallback)}
              step={0.001}
            />
          </Col>
          <Col sm={24} md={8}>
            <Tooltip
              placement="right"
              title={`Allowed deviation from target latency before ${
                isFallback ? "fallback" : "catchup"
              }  by seeking is done.`}
            >
              <Badge
                color="cyan"
                text={`SeekThreshold:  ${
                  isFallback ? this.state.fallbackConfig.seekThreshold : this.state.catchupConfig.seekThreshold
                }`}
                style={{ fontWeight: 1 }}
              />
            </Tooltip>
            <Slider
              min={CONFIG_RANGE[0]}
              max={CONFIG_RANGE[1]}
              marks={marks}
              defaultValue={
                isFallback ? this.state.fallbackConfig.seekThreshold : this.state.catchupConfig.seekThreshold
              }
              value={isFallback ? this.state.fallbackConfig.seekThreshold : this.state.catchupConfig.seekThreshold}
              onChange={(value) => this.onConfigSliderChange(value, 3, isFallback)}
              step={0.01}
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  render() {
    return <div>{this.renderLatencySlider()}</div>;
  }
}

export default LatencySlider;
