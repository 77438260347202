import React, { Component } from "react";
import { Line } from "@ant-design/plots";
import { Collapse } from "antd";

const { Panel } = Collapse;

class BandwidthChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segment: 0,
      lines: [],
    };
  }

  update = (object) => {
    const newArray = this.getNewArr(object);
    const newSegment = object.segment > -1 ? object.segment : this.state.segment + 1;

    //  and update the state with new arrays.
    this.setState({ segment: newSegment, lines: newArray });
  };

  reset = () => {
    this.setState({ lines: [], segment: 0 });
  };

  getNewArr = (object) => {
    const array = this.state.lines;

    if (array.length === 50) {
      // remove first item
      array.shift();
    }

    // add new points to the array.
    array.push({
      Segment: object.segment > -1 ? object.segment : this.state.segment,
      Speed: object.bandwidth,
      key: object.type,
    });

    return array;
  };

  render() {
    if (this.state.lines.length <= 2) {
      return "";
    }

    return (
        <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
          <Panel header="Download Speed" key="1" className="site-collapse-custom-panel">
            <Line
              height={this.props.height}
              data={this.state.lines}
              animate={false}
              padding="auto"
              xField="Segment"
              yField="Speed"
              seriesField="key"
              stepType="hvh"
              legend={{ position: "top" }}
              zoom={{ position: "topright" }}
              autoFit="true"
              point={{ size: 5, shape: "diamond" }}
              tooltip={{
                fields: ["Segment", "Speed"],
                showTitle: false,
              }}
              scale={{
                y: {
                  tickCount: 3,
                },
              }}
              xAxis={{
                tickCount: 5,
                title: {
                  text: "Segment",
                  style: {
                    fontSize: this.props.labelFontSize,
                  },
                },
              }}
              yAxis={{
                tickCount: 5,
                title: {
                  text: "Download Speed (Mbps)",
                  style: {
                    fontSize: this.props.labelFontSize,
                  },
                },
              }}
            />
          </Panel>
        </Collapse>
    );
  }
}

export default BandwidthChart;
