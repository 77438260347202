import React, { Component } from "react";
import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";

class JsonTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      json: this.props.json,
      treeData: this.generateTreeData([], this.props.json, "", 0),
    };
  }

  onExpand = (newExpandedKeys: string[]) => {
    this.setState({
      expandedKeys: [...newExpandedKeys],
    });
  };

  onRightClick = (event, node) => {
    if (event !== null) {
      console.log("onRightClick: " + JSON.stringify(event) + " node: " + node);
    }
  };

  treeData: DataNode[] = [
    {
      title: "parent 1",
      key: "0-0",
      children: [
        {
          title: "parent 1-0",
          key: "0-0-0",
          disabled: true,
          children: [
            {
              title: "leaf",
              key: "0-0-0-0",
            },
            {
              title: "leaf",
              key: "0-0-0-1",
            },
          ],
        },
        {
          title: "parent 1-1",
          key: "0-0-1",
          children: [{ title: <span style={{ color: "#1890ff" }}>sss</span>, key: "0-0-1-0" }],
        },
      ],
    },
  ];

  generateTreeData = (data, json, parentKey) => {
    if (json !== null) {
      for (let key in json) {
        if (json.hasOwnProperty(key)) {
          const value = json[key];

          // checking out whether it's an object or not.
          if (typeof value === "object" && value !== null) {
            const newArray: DataNode[] = [];
            var children = this.generateTreeData(newArray, value, key);
            data.push({
              title: `${key}`,
              key: key + parentKey + Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
              children: children,
            });
          } else {
            data.push({
              title: `${key} : ${json[key]}`,
              key: key + parentKey + Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
            });
          }
        }
      }
    }
    return data;
  };

  getTreeData = () => {
    if (this.state.treeData == null || this.state.json !== this.props.json) {
      const data: DataNode[] = [];
      const treeData = this.generateTreeData(data, this.props.json, "", 0);
      this.setState({
        json: this.props.json,
        treeData: treeData,
      });
      return treeData;
    } else {
      return this.state.treeData;
    }
  };

  onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  onSelect2 = (value) => {};

  render() {
    if (this.props.json === null) {
      // don't show anything
      return "";
    }

    return (
      <Tree
        defaultExpandAll={this.props.expendAll === true}
        selectable={false}
        treeData={this.getTreeData()}
        // onRightClick={(event, node) => this.onRightClick(event, node)}
        // onExpand={this.onExpand()}
        // expandedKeys={this.state.expandedKeys}
      />
    );
  }
}

export default JsonTree;
