import React, { Component } from "react";
import { LogLevel, Player, PlayerEvent } from "bitmovin-player";
import { UIFactory } from "bitmovin-player/bitmovinplayer-ui";
import "bitmovin-player/bitmovinplayer-ui.css";
import { getRightBitrateId, PLAYER_ERROR_MESSAGES, getSegment, isSuitableForLowLatency } from "./BitMovinPlayerHelper";
import { getBandwidth } from "../../utils/Utility";
import "./styles.css";

const keySystems = {
  widevine: ["com.widevine.alpha"],
  playready: ["com.microsoft.playready", "com.youtube.playready"],
  primetime: ["com.adobe.primetime", "com.adobe.access"],
  fairplay: ["com.apple.fairplay", "com.apple.fps.1_0", "com.apple.fps.2_0"],
};

class BitmovinPlayer extends Component {
  state = {
    player: null,
    rangedVideoQualities: [], // the range of the bitrate slider.
  };

  updateCount = 0;
  segmentDuration = 0;
  self = this;

  playerConfig = {
    key: "ef6ef141-400a-4f91-8f15-ba9033d615ea",
    location: {
      ui: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
      ui_css: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
    },
    skin: {
      screenLogoImage: "",
    },

    logs: {
      level: LogLevel.DEBUG,
    },
    playback: {
      autoplay: true,
      muted: true,
    },
    // tweaks: {
    //   FAIRPLAY_IGNORE_DUBLICATE_INIT_DATA_KEY_ERRORS: true,
    // RESTART_THRESHOLD: 0.2,
    // RESTART_THRESHOLD_DELTA: 0.05,
    // STARTUP_THRESHOLD: 0.2,
    // STARTUP_THRESHOLD_DELTA: 0.05,
    // END_OF_BUFFER_TOLERANCE: 0.05,
    // LIVE_EDGE_DISTANCE: 0.5,
    // LOW_LATENCY_BUFFER_GUARD: 0.8,
    // CHUNKED_CMAF_STREAMING: true,
    // },

    events: {
      [PlayerEvent.AudioPlaybackQualityChanged]: (data) => {
        console.log("On Audio Playback Quality Changed: " + JSON.stringify(data));
        //this.updateCharts();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioPlaybackQualityChanged",
          time: Date.now(),
          text: "AudioPlaybackQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.VideoPlaybackQualityChanged]: (data) => {
        console.log("On Video Playback Quality Changed: " + JSON.stringify(data));
        //this.updateCharts();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoPlaybackQualityChanged",
          time: Date.now(),
          text: "VideoPlaybackQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.StallStarted]: (data) => {
        console.log("On Buffering Started: " + JSON.stringify(data));
        //this.updateCharts();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "StallStarted",
          time: Date.now(),
          text: "Buffering Started",
          detail: data,
        });
      },
      [PlayerEvent.StallEnded]: (data) => {
        console.log("On Buffering Ended: " + JSON.stringify(data));
        //this.updateCharts();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "StallEnded",
          time: Date.now(),
          text: "Buffering Ended",
          detail: data,
        });
      },
      [PlayerEvent.Playing]: (data) => {
        console.log("On Playing: " + JSON.stringify(data));
        // add source to show on detail
        data["source"] = this.state.player.getSource();

        //this.updateCharts();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Playing",
          time: Date.now(),
          text: "Playing",
          detail: data,
        });
      },
      [PlayerEvent.Paused]: (data) => {
        console.log("On Paused: " + JSON.stringify(data));
        // add source to show on detail
        data["source"] = this.state.player.getSource();

        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Paused",
          time: Date.now(),
          text: "Paused",
          detail: data,
        });

        this.updateCharts();
      },
      [PlayerEvent.Play]: (data) => {
        console.log("On Play: " + JSON.stringify(data));
        // add source to show on detail
        data["source"] = this.state.player.getSource();

        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Play",
          time: Date.now(),
          text: "Play",
          detail: data,
        });
      },
      [PlayerEvent.MetadataParsed]: (data) => {
        // console.log("On Metadata Parsed: " + JSON.stringify(data));
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "MetadataParsed",
          time: Date.now(),
          text: "MetadataParsed",
          detail: data,
        });
      },
      [PlayerEvent.Ready]: (data) => {
        console.log("On Ready: " + JSON.stringify(data));

        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Ready",
          time: Date.now(),
          text: "Ready",
          detail: data,
        });

        this.updateCharts();
      },
      [PlayerEvent.SourceLoaded]: (data) => {
        // console.log("[DEBUG] getVideoQuality: " + JSON.stringify(this.state.player.getVideoQuality()));
        console.log("On Loaded: " + JSON.stringify(data));

        // add source to show on detail
        data["source"] = this.state.player.getSource();
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SourceLoaded",
          time: Date.now(),
          text: "Loaded",
          detail: data,
        });

        this.updateCharts();
      },
      [PlayerEvent.Error]: (data) => {
        var message = PLAYER_ERROR_MESSAGES.get(data.code);
        this.props.updateTimeline({
          type: "event",
          subtype: "error",
          id: "Error",
          time: Date.now(),
          text: "Error : " + message,
          detail: data,
        });
        console.log("On Error: " + message + " - " + JSON.stringify(data));
        this.updateCharts();
      },
      [PlayerEvent.Warning]: (data) => {
        var message = PLAYER_ERROR_MESSAGES.get(data.code);
        console.log("On Warning: " + message);
        this.props.updateTimeline({
          type: "event",
          subtype: "warning",
          id: "Warning",
          time: Date.now(),
          text: "Warning: " + message,
          detail: data,
        });
      },
      [PlayerEvent.Seek]: (data) => {
        console.log("On Seek Finished: " + JSON.stringify(data));
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Seek",
          time: Date.now(),
          text: "Seek Finished",
          detail: data,
        });
        this.updateCharts();
      },
      [PlayerEvent.VideoAdaptation]: (data) => {
        // console.log("On VideoAdaptation: " + JSON.stringify(data));
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoAdaptation",
          time: Date.now(),
          text: "VideoAdaptation",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.LatencyModeChanged]: (data) => {
        console.log("On LatencyModeChanged Changed: " + JSON.stringify(data));
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "LatencyModeChanged",
          time: Date.now(),
          text: "LatencyModeChanged",
          detail: data,
        });
      },
      [PlayerEvent.SegmentPlayback]: (data) => {
        // console.log("On SegmentPlayback: " + JSON.stringify(data));

        this.segmentDuration = data.duration;
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SegmentPlayback",
          time: Date.now(),
          text: "SegmentPlayback",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.TimeChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "TimeChanged",
          time: Date.now(),
          text: "TimeChanged",
          detail: data,
          checked: false,
        });

        this.updateCount++;

        if (this.updateCount % 4 === 1) {
          this.updateCharts();
        }
      },
      [PlayerEvent.TimeShifted]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "TimeShifted",
          time: Date.now(),
          text: "TimeShifted",
          detail: data,
        });
      },
      [PlayerEvent.VolumeChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VolumeChanged",
          time: Date.now(),
          text: "VolumeChanged",
          detail: data,
        });
      },
      [PlayerEvent.Muted]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Muted",
          time: Date.now(),
          text: "Muted",
          detail: data,
        });
      },
      [PlayerEvent.Unmuted]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "Unmuted",
          time: Date.now(),
          text: "Unmuted",
          detail: data,
        });
      },
      [PlayerEvent.PlayerResized]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "PlayerResized",
          time: Date.now(),
          text: "PlayerResized to Width: " + data.width + " - Height: " + data.height,
          detail: null,
        });
      },
      [PlayerEvent.PlaybackFinished]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "PlaybackFinished",
          time: Date.now(),
          text: "PlaybackFinished",
          detail: data,
        });
      },
      [PlayerEvent.AudioChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioChanged",
          time: Date.now(),
          text: "AudioChanged",
          detail: data,
        });
      },
      [PlayerEvent.AudioAdded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioAdded",
          time: Date.now(),
          text: "AudioAdded",
          detail: data,
        });
      },
      [PlayerEvent.AudioRemoved]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioRemoved",
          time: Date.now(),
          text: "AudioRemoved",
          detail: data,
        });
      },
      [PlayerEvent.VideoQualityChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoQualityChanged",
          time: Date.now(),
          text: "VideoQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.VideoQualityAdded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoQualityAdded",
          time: Date.now(),
          text: "VideoQualityAdded",
          detail: data,
        });
      },
      [PlayerEvent.VideoQualityRemoved]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoQualityRemoved",
          time: Date.now(),
          text: "VideoQualityRemoved",
          detail: data,
        });
      },
      [PlayerEvent.AudioQualityChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioQualityChanged",
          time: Date.now(),
          text: "AudioQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.AudioQualityAdded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioQualityAdded",
          time: Date.now(),
          text: "AudioQualityAdded",
          detail: data,
        });
      },
      [PlayerEvent.AudioQualityRemoved]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioQualityRemoved",
          time: Date.now(),
          text: "AudioQualityRemoved",
          detail: data,
        });
      },
      [PlayerEvent.PlaybackSpeedChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "PlaybackSpeedChanged",
          time: Date.now(),
          text: "PlaybackSpeedChanged",
          detail: data,
        });
      },
      [PlayerEvent.DurationChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "DurationChanged",
          time: Date.now(),
          text: "DurationChanged",
          detail: data,
        });
      },
      [PlayerEvent.ViewModeChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "ViewModeChanged",
          time: Date.now(),
          text: "ViewModeChanged",
          detail: data,
        });
      },
      [PlayerEvent.ModuleReady]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "ModuleReady",
          time: Date.now(),
          text: "ModuleReady",
          detail: data,
        });
      },
      [PlayerEvent.SubtitleEnabled]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SubtitleEnabled",
          time: Date.now(),
          text: "SubtitleEnabled",
          detail: data,
        });
      },
      [PlayerEvent.SubtitleDisabled]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SubtitleDisabled",
          time: Date.now(),
          text: "SubtitleDisabled",
          detail: data,
        });
      },
      [PlayerEvent.TargetLatencyChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "TargetLatencyChanged",
          time: Date.now(),
          text: "TargetLatencyChanged",
          detail: data,
        });
      },
      [PlayerEvent.LatencyModeChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "LatencyModeChanged",
          time: Date.now(),
          text: "LatencyModeChanged",
          detail: data,
        });
      },
      [PlayerEvent.LicenseValidated]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "LicenseValidated",
          time: Date.now(),
          text: "LicenseValidated",
          detail: data,
        });
      },
      [PlayerEvent.DrmLicenseAdded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "DrmLicenseAdded",
          time: Date.now(),
          text: "DrmLicenseAdded",
          detail: data,
        });
      },
      [PlayerEvent.AspectRatioChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AspectRatioChanged",
          time: Date.now(),
          text: "AspectRatioChanged",
          detail: data,
        });
      },
      [PlayerEvent.VideoDownloadQualityChange]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoDownloadQualityChange",
          time: Date.now(),
          text: "VideoDownloadQualityChange",
          detail: data,
        });
      },
      [PlayerEvent.AudioDownloadQualityChange]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioDownloadQualityChange",
          time: Date.now(),
          text: "AudioDownloadQualityChange",
          detail: data,
        });
      },
      [PlayerEvent.VideoDownloadQualityChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "VideoDownloadQualityChanged",
          time: Date.now(),
          text: "VideoDownloadQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.AudioDownloadQualityChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "AudioDownloadQualityChanged",
          time: Date.now(),
          text: "AudioDownloadQualityChanged",
          detail: data,
        });
      },
      [PlayerEvent.CueParsed]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "CueParsed",
          time: Date.now(),
          text: "CueParsed",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.CueEnter]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "CueEnter",
          time: Date.now(),
          text: "CueEnter",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.CueUpdate]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "CueUpdate",
          time: Date.now(),
          text: "CueUpdate",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.CueExit]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "CueExit",
          time: Date.now(),
          text: "CueExit",
          detail: data,
          checked: false,
        });
      },
      [PlayerEvent.MetadataChanged]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "MetadataChanged",
          time: Date.now(),
          text: "MetadataChanged",
          detail: data,
        });
      },
      [PlayerEvent.SourceUnloaded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SourceUnloaded",
          time: Date.now(),
          text: "SourceUnloaded",
          detail: data,
        });
      },
      [PlayerEvent.PeriodSwitched]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "PeriodSwitched",
          time: Date.now(),
          text: "PeriodSwitched",
          detail: data,
        });
      },
      [PlayerEvent.DVRWindowExceeded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "DVRWindowExceeded",
          time: Date.now(),
          text: "DVRWindowExceeded",
          detail: data,
        });
      },
      [PlayerEvent.SubtitleRemoved]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SubtitleRemoved",
          time: Date.now(),
          text: "SubtitleRemoved",
          detail: data,
        });
      },
      [PlayerEvent.SubtitleAdded]: (data) => {
        this.props.updateTimeline({
          type: "event",
          subtype: "info",
          id: "SubtitleAdded",
          time: Date.now(),
          text: "SubtitleAdded",
          detail: data,
        });
      },
      [PlayerEvent.DownloadFinished]: (data) => {
        //console.log("On DownloadFinished: " + JSON.stringify(data));

        if (data.size > 1000) {
          let type;

          if (data.downloadType === "media/video" && data.mimeType.indexOf("video") > -1) {
            type = "video";
          } else if (data.downloadType === "media/audio" && data.mimeType.indexOf("audio") > -1) {
            type = "audio";
          } else if (data.downloadType.indexOf("manifest") > -1) {
            type = "manifest";
          } else {
            type = "unknown";
            console.log("PlayerEvent.DownloadFinished : " + JSON.stringify(data));
          }

          this.props.updateTimeline({
            type: "event",
            subtype: "info",
            id: "DownloadFinished",
            time: Date.now(),
            text: type + " type download finished",
            detail: data,
            checked: false,
          });

          var downloadSpeed = Math.round((data.size * 8) / data.downloadTime / 1000000);

          this.props.updateBandwidth({ bandwidth: downloadSpeed, segment: getSegment(data.url), type: type });
        }
      },
    },
    adaptation: {
      desktop: {
        limitToPlayerSize: true,
        onVideoAdaptation: (event) => {
          const availableVideoQualities = event.representations.sort((a, b) => (a.bandwidth > b.bandwidth ? 1 : -1));

          // update bitrate Slider
          this.props.updateBitrateSlider({
            availableVideoQualities: availableVideoQualities,
            suggestedBitrateId: event.suggested,
            currentBitrate: this.state.player.getDownloadedVideoData().bitrate,
          });

          return getRightBitrateId(event, this.state.rangedVideoQualities);
        },
      },
    },
  };

  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  componentDidMount() {
    this.setupPlayer();
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  updateRangedVideoQuality = (array) => {
    this.setState({ rangedVideoQualities: array });
  };

  setTargetLatency = (number) => {
    this.state.player.lowlatency.setTargetLatency(number);
  };

  getSupportedDRMSystem = (player) => {
    var retVal = [];

    return player.getSupportedDRM().then((drmSystem) => {
      drmSystem.forEach(function (element) {
        var match = keySystems.widevine.find(function (obj) {
          return obj === element;
        })
          ? "widevine"
          : undefined;
        if (!match) {
          match = keySystems.playready.find(function (obj) {
            return obj === element;
          })
            ? "playready"
            : undefined;
        }
        if (!match) {
          match = keySystems.fairplay.find(function (obj) {
            return obj === element;
          })
            ? "fairplay"
            : undefined;
        }
        if (match) {
          retVal.push(match);
        }
      });

      const drmSystems = [];

      Object.keys(keySystems).forEach((key) => {
        drmSystems.push({ [key]: retVal.indexOf(key) < 0 ? false : true });
      });

      this.props.updateStatsBeforePlaying({
        supportedDRMSystems: drmSystems,
        supportedTech: player.getSupportedTech(),
      });
    });
  };

  updateCharts = () => {
    const player = this.state.player;
    var latency = null;

    var isLowLatency = isSuitableForLowLatency(
      player.lowlatency.getLatency(),
      player.getManifest(),
      player.getStreamType()
    );

    if (isLowLatency) {
      latency = {
        currentLatency: player.lowlatency.getLatency(),
        targetLatency: player.lowlatency.getTargetLatency(),
        timeShift: player.getTimeShift(),
      };

      this.props.updateLatencySlider(latency);
    }

    this.setLowLatencyConfig(isLowLatency);

    this.props.updateDiagrams({
      videoBuffer: player.getVideoBufferLength(),
      audioBuffer: player.getAudioBufferLength(),
      bitrate: player.getDownloadedVideoData().bitrate,
      latency: latency,
    });

    this.props.updateStats({
      duration: player.getDuration(),
      playbackPosition: player.getCurrentTime(),
      tracks: player.getAvailableAudio(),
      bufferedRanges: player.getBufferedRanges(),
      videoData: player.getPlaybackVideoData(),
      availableAudioQualities: player.getAvailableAudioQualities().sort((a, b) => (a.bitrate > b.bitrate ? 1 : -1)),
      availableVideoQualities: player.getAvailableVideoQualities().sort((a, b) => (a.bitrate > b.bitrate ? 1 : -1)),
      bitrate: player.getDownloadedVideoData().bitrate,
      manifest: player.getManifest(),
      streamType: player.getStreamType(),
      droppedVideoFrames: player.getDroppedVideoFrames(),
      isLive: player.isLive(),
      isClear: player.getSource().drm === undefined,
    });
  };

  /**
   * Sets low latency configs if the content is fully compliant with CMAF specs.
   */
  setLowLatencyConfig = (isSuitableForLowLatency) => {
    if (isSuitableForLowLatency) {
      // set default configuration for catchup
      this.state.player.lowlatency.setCatchupConfig({
        playbackRate: 1.2,
        playbackRateThreshold: 0.075,
        seekThreshold: 5,
      });

      // set default configuration for fallback
      this.state.player.lowlatency.setFallbackConfig({
        playbackRate: 0.95,
        playbackRateThreshold: 0.075,
        seekThreshold: 5,
      });
    } else {
      this.state.player.lowlatency.setCatchupConfig(null);
      this.state.player.lowlatency.setFallbackConfig(null);
    }
  };

  changeLowLatencyConfig = (isFallback, object) => {
    if (isFallback) {
      this.state.player.lowlatency.setFallbackConfig(object);
    } else {
      this.state.player.lowlatency.setCatchupConfig(object);
    }
  };

  setupPlayer = () => {
    const player = new Player(this.playerRef.current, this.playerConfig);
    UIFactory.buildDefaultUI(player);

    this.setState({
      ...this.state,
      player,
    });

    this.getSupportedDRMSystem(player);
  };

  loadContent = async (playerSource) => {
    //  console.log("LoadContent has been called: " + JSON.stringify(playerSource));

    this.state.player.load(playerSource).then(
      () => {
        // console.log("[DEBUG] - The player loaded the source successfully.");
      },
      (error) => {
        console.log("[DEBUG] - Error while loading source");

        this.props.updateTimeline({
          type: "event",
          subtype: "error",
          id: "Load",
          time: Date.now(),
          text: "Error while loading source",
          detail: { error: error },
        });
      }
    );

    // clean state and local variables
    this.setState({ rangedVideoQualities: [] });
    this.updateCount = 0;
    this.segmentDuration = 0;
  };

  onContentChange = (playSource) => {
    //console.log("OnContentChange has been called: " + playSource);
    this.loadContent(playSource);
  };

  destroyPlayer() {
    console.log("DestroyPlayer has been called.");

    if (this.state.player != null) {
      this.state.player.destroy();
      this.setState({
        ...this.state,
        player: null,
      });
    }
  }

  render() {
    return <div id="player" ref={this.playerRef} />;
  }
}

export default BitmovinPlayer;
