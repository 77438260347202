export const LOCAL_STORAGE_KEYS = {
  IS_LOGGED_IN: "isLoggedIn",
  LOGIN_REMEMBER: "login_remember",
  AVS_REFRESH_TOKEN: "avs_refresh_token",
  REGION_ID: "region_id",
  DEVICE_ID: "device_id",
  LIVE_PACKAGE_IDS: "live_package_ids",
  ENVIRONMENT: "environment",
  CHANNEL_LIST: "channel_list",
  VOD_LIST: "vod_list",
  VOD_LIST_TAB_PAGE: "VodListTab_page",
  HAS_MORE_VOD_LIST: "VodListTab_hasMore",
  LAST_TOKEN:"last_token"
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  var item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};

export const removeLocalStorage = (key) => {
  // remove
  localStorage.removeItem(key);
};

export const clearLocalStorages = () => {
  localStorage.clear();
  console.log("[DEBUG] ClearLocalStorages has been called.");
};

//The difference is that with sessionStorage, the data is persisted only until the window or tab is closed. With localStorage, the data is persisted until the user manually clears the browser cache or until your web app clears the data.
