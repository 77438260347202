import axios from "axios";
import URLConstants from "../../../constants/URLs";
import { removeLocalStorage, LOCAL_STORAGE_KEYS, setLocalStorage } from "../../../utils/LocalStorageManager";

const { LAB_TOKEN_GENERATOR } = URLConstants;

const getToken = async (subject, updateTimeline, authToken) => {
  // sample:     https://tokengen.telus-lab.core.verimatrixcloud.net/token/?expiresIn=90&subject=232008200004
  const url = LAB_TOKEN_GENERATOR + subject;

  if(authToken) {
    updateTimeline({
      type: "main",
      subtype: "info",
      id: "LabTokenGeneratorResponse",
      time: Date.now(),
      text: "Lab token generator response for subject: " + subject,
      detail: { url: url, response: authToken },
    });
    return {
      result: true,
      token: authToken,
    };
  } else {
    const result = await axios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
      withCredentials: true,
      returnResponseObject: true,
    })
      .then((response) => {
        updateTimeline({
          type: "main",
          subtype: "info",
          id: "LabTokenGeneratorResponse",
          time: Date.now(),
          text: "Lab token generator response for subject: " + subject,
          detail: { url: url, response: response },
        });

        if (response.data.status === true) {
          return {
            result: true,
            jwtId: response.data.jwtId,
            token: response.data.token,
          };
        }
        return { result: false };
      })
      .catch((error) => {
        console.log(" getToken action has been failed: " + JSON.stringify(error));

        updateTimeline({
          type: "main",
          subtype: "error",
          id: "LabTokenGeneratorResponse",
          time: Date.now(),
          text: "Lab Token Generator Response has failed for subject: " + subject,
          detail: { url: url, error: error },
        });

        return { result: false, error: error };
      });

    return result;
  }
};


/**
 * Converts a content to the object that the player expects
 */
 export const contentToPlayerSource = (content, token) => {
  const source = {};
  const isClear = content.license_url === undefined;

  // get content type
  let contentType;
  switch (content.content_type) {
    case 102:
      contentType = "hls";
      break;
    case 101:
      contentType = "dash";
      break;
    default:
      contentType = "smooth";
  }

  source[contentType] = content.url;

  if (!isClear) {
    const drm = {};

    // get license_Type
    let licenseType;
    switch (content.license_type) {
      case 301:
        licenseType = "widevine";
        break;
      case 302:
        licenseType = "playready";
        break;
      case 303:
        licenseType = "primetime";
        break;
      case 304:
        licenseType = "fairplay";
        break;
      default:
        console.error("invalid content: " + JSON.stringify(content));
    }

    drm[licenseType] = {};
    drm[licenseType].LA_URL = content.license_url;

    if (token !== undefined) {
      if (licenseType === "fairplay") {
        drm[licenseType].certificateURL = content.license_cert_url;
        drm[licenseType].headers = { authorization: token, "Content-Type": "application/json" };

        drm[licenseType].withCredentials = true;
        drm[licenseType].useUint16InitData = true;

        drm[licenseType].prepareContentId = (contentId) => {
          return contentId.slice(8);
        };
        drm[licenseType].prepareMessage = (event, session) => {
          return JSON.stringify({
            spc: event.messageBase64Encoded,
          });
        };
        drm[licenseType].prepareLicense = (data) => {
          let parsed = JSON.parse(data);
          return parsed.ckc;
        };
      } else {
        drm[licenseType].headers = { authorization: token };
        drm[licenseType].withCredentials = true;
      }
    }

    source["drm"] = drm;
  }

  console.log("source: " + JSON.stringify(source));

  // create a source considering the media url.
  return source;
};

export const generatePlayerSource = async (content, updateTimeline) => {
  if (content.subject != null) {
    // fetch token by giving the subject value.
    const result = await getToken(content.subject, updateTimeline, content.authToken);

    if (result.result) {
      // add token variable but no need to store it.
      content.token = result.token;
      content.time = Date.now();

      // caching the playing content for stats panel.
      setLocalStorage(LOCAL_STORAGE_KEYS.LAST_TOKEN, content);

      return contentToPlayerSource(content, result.token);
    }
  }

  // remove playing content
  removeLocalStorage(LOCAL_STORAGE_KEYS.LAST_TOKEN);

  // convert content to the object that the player expects
  return contentToPlayerSource(content, content.token);
};
