import React, { Component } from "react";
import Card from "antd/lib/card";
import CustomTab from "./CustomTabComponent";
import DemoListTab from "./demos/DemoListTabComponent";
import ChannelListTab from "./channels/ChannelListTabComponent";
import VodListTab from "./vods/VodListTabComponent";
import LoginTab from "./login/LoginTabComponent";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../utils/LocalStorageManager";

var tabList = [
  {
    key: "login",
    tab: "Login",
  },
  {
    key: "custom",
    tab: "Custom URL",
  },
  {
    key: "clear",
    tab: "Clear",
  },
  {
    key: "drm",
    tab: "DRM",
  },
];

var tabsAfterLogin = [
  {
    key: "channels",
    tab: "Channels",
  },
  {
    key: "vod",
    tab: "VODs",
  },
];

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: tabList[0].key,
      selectedItem: null,
    };
  }

  onTabChange = (selectedTabKey) => {
    this.setState({ activeTabKey: selectedTabKey });
  };

  onContentSelected = (selectedItem, playerSource) => {
    // console.log(
    //   "onContentSelected has been called: " + JSON.stringify(selectedItem)
    // );

    // if (selectedItem == null) {
    // update the index value of the select item.
    this.setState({ selectedItem: selectedItem });
    // }

    // load the content.
    this.props.onLoadContent(playerSource);
  };

  getTabList = () => {
    // is logged in?
    if (getLocalStorage(LOCAL_STORAGE_KEYS.IS_LOGGED_IN)) {
      // check whether the tabs already pushed or not.
      if (tabList.indexOf(tabsAfterLogin[0]) === -1) {
        tabList.push(...tabsAfterLogin);
      }
    }
    return tabList;
  };

  render() {
    const contentList = {
      login: <LoginTab />,
      custom: (
        <CustomTab
          onContentSelected={this.onContentSelected}
          updateTimeline={this.props.updateTimeline}
          getSupportedDRMSystems={this.props.getSupportedDRMSystems}
        />
      ),
      clear: (
        <DemoListTab
          isClear={true}
          onContentSelected={this.onContentSelected}
          selectedItem={this.state.selectedItem}
          tab={tabList[2].key}
          updateTimeline={this.props.updateTimeline}
        />
      ),
      drm: (
        <DemoListTab
          isClear={false}
          onContentSelected={this.onContentSelected}
          selectedItem={this.state.selectedItem}
          updateTimeline={this.props.updateTimeline}
          tab={tabList[3].key}
        />
      ),
      channels: (
        <ChannelListTab
          onContentSelected={this.onContentSelected}
          selectedItem={this.state.selectedItem}
          updateTimeline={this.props.updateTimeline}
          tab={tabList.length > 4 ? tabList[4].key : ""}
        />
      ),
      vod: (
        <VodListTab
          onContentSelected={this.onContentSelected}
          selectedItem={this.state.selectedItem}
          updateTimeline={this.props.updateTimeline}
          tab={tabList.length > 5 ? tabList[5].key : ""}
        />
      ),
    };

    return (
      <Card
        style={{ width: "100%", height: "100%" }}
        tabList={this.getTabList()}
        activeTabKey={this.state.activeTabKey}
        onTabChange={(key) => {
          this.onTabChange(key);
        }}
      >
        {contentList[this.state.activeTabKey]}
      </Card>
    );
  }
}

export default Tabs;
