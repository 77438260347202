import React, { Component } from "react";
import { Space, Table, Tag, Collapse } from "antd";

const { Panel } = Collapse;

interface DataType {
  id: string;
  bitrate: number;
  width: number;
  height: number;
  codec: string;
  label: string;
}

class AvailableQualitiesStats extends Component {
  columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width:150,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Bitrate",
      dataIndex: "bitrate",
      key: "bitrate",
      width:100,
    },
    {
      title: "Width",
      key: "width",
      dataIndex: "width",
      width:100,
    },
    {
      title: "Height",
      key: "height",
      dataIndex: "height",
      width:100,
    },
    {
      title: "Codec",
      key: "codec",
      width:130,
      dataIndex: "codec",
      render: (_, { codec }) => (
        <Tag color="geekblue" key={codec}>
          {codec.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Label",
      key: "label",
      width:170,
      render: (_, { label }) => (
        <Space size="middle">
          <a>{label}</a>
        </Space>
      ),
    },
  ];

  renderTable = (title, array, key) => {
    if (array.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <div>
        <Collapse defaultActiveKey={key}>
          <Panel header={title} key={key}>
            <Table
              columns={this.columns}
              dataSource={array}
              size="small"
              pagination={false}
              bordered
              scroll={{ y: 160 }}
            />
          </Panel>
        </Collapse>
      </div>
    );
  };

  render() {
    if (this.props.video.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <div>
        {this.renderTable("Available Video Qualities", this.props.video, "stats-available-video-qualities")}
        <br />
        {this.renderTable("Available Audio Qualities", this.props.audio, "stats-available-audio-qualities")}
        <br />
      </div>
    );
  }
}

export default AvailableQualitiesStats;
