import React, { Component } from "react";
import { Col, Row, Divider, Statistic, Space, Typography, message } from "antd";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";
import JsonTree from "../../common/JsonTreeComponent";
import jwt_decode from "jwt-decode";

const { Paragraph, Text } = Typography;
const { Countdown } = Statistic;

class TokenStats extends Component {
  onCountdownFinish = () => {
    message.info("The last JWT token has expired.");
  };

  numericDateToDate = (milliseconds) => {
    // converting NumericDate to date
    const d_utc = new Date(milliseconds * 1000);
    return new Date(d_utc.valueOf() - d_utc.getTimezoneOffset() * 60 * 1000);
  };

  render() {
    const content = getLocalStorage(LOCAL_STORAGE_KEYS.PLAYING_CONTENT);

    if (content === null) {
      // don't show anything
      return "";
    }

    var decodedToken = jwt_decode(content.token);

    return (
      <div>
        <Divider orientation="left">Token :</Divider>
        <Row>
          <Col xs={24} sm={12} md={7}>
            <Space direction="vertical">
              <Text type="secondary">JWT Token</Text>
              <Paragraph style={{ width: 200 }} ellipsis={{ rows: 15, expandable: true, symbol: "more" }}>
                {content.token}
              </Paragraph>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={5}>
            <Row span={6}>
              <Countdown
                title="Expiration time"
                value={this.numericDateToDate(decodedToken.exp)}
                onFinish={this.onCountdownFinish}
              />
            </Row>
            <br />
            <Row>
              <Statistic title="Fetched at" value={new Date(content.time).toLocaleTimeString()} />
            </Row>
          </Col>

          <Col xs={24} sm={24} md={10}>
            <Space direction="vertical">
              <Text type="secondary">Decoded Token</Text>
              <JsonTree json={decodedToken} />
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TokenStats;
