import React, { Component } from "react";
import { Collapse, Timeline, Space, Col, Row, Typography, Checkbox, Button, Tooltip, Affix, Badge } from "antd";
import {
  CloseSquareTwoTone,
  WarningTwoTone,
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import JsonTree from "../common/JsonTreeComponent";

const { Panel } = Collapse;
const { Text } = Typography;

const HEIGHT = 400;

class Timeline1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ids: new Map(),
      isRecording: false,
    };
  }

  updateTimeline = (object) => {
    if (!this.state.isRecording) {
      // ignore events
      return;
    }

    const array = this.state.data;
    // add new points to the array.
    array.push(object);

    if (this.state.ids.get(object.id) === undefined) {
      const ids = this.state.ids;
      ids.set(object.id, object.checked === false ? false : true);

      this.setState({ data: array, ids: ids });
    } else {
      this.setState({ data: array });
    }
  };

  resetTimeline = () => {
    this.setState({ data: [] });
  };

  onCheckBoxChanged = (e: CheckboxChangeEvent) => {
    const ids = this.state.ids;
    ids.set(e.target.value, e.target.checked);

    this.setState({ ids: ids });
  };

  onRecordingClicked = () => {
    this.setState({ isRecording: !this.state.isRecording });
  };

  formatDate = (milliseconds) => {
    function toString(number, padLength) {
      return number.toString().padStart(padLength, "0");
    }
    const date = new Date(milliseconds);
    return (
      toString(date.getFullYear(), 4) +
      "-" +
      toString(date.getMonth() + 1, 2) +
      "-" +
      toString(date.getDate(), 2) +
      " " +
      toString(date.getHours(), 2) +
      ":" +
      toString(date.getMinutes(), 2) +
      ":" +
      toString(date.getSeconds(), 2) +
      "." +
      toString(date.getMilliseconds(), 3)
    );
  };

  getItemIcon = (item) => {
    if (item.subtype === "error") {
      return <CloseSquareTwoTone twoToneColor="#e05c55" style={{ fontSize: "16px" }} />;
    } else if (item.subtype === "warning") {
      return <WarningTwoTone twoToneColor="#e7b041" style={{ fontSize: "16px" }} />;
    }

    return "";
  };

  getItemColor = (item) => {
    if (item.type === "main") {
      return "green ";
    }

    return "blue";
  };

  renderEventList = (title) => {
    if (this.state.ids.size === 0) {
      // don't show anything
      return "";
    }

    return (
      <Space direction="vertical">
        <Text type="secondary">{title}</Text>
        <div
          id="scrollableDiv"
          style={{
            height: HEIGHT,
            overflow: "auto",
          }}
        >
          {Array.from(this.state.ids, ([key, value]) => (
            <div
              style={{
                marginRight: 10,
              }}
            >
              <Checkbox onChange={this.onCheckBoxChanged} checked={this.state.ids.get(key)} value={key}>
                {" "}
                {key}
              </Checkbox>
            </div>
          ))}
        </div>
      </Space>
    );
  };

  renderTimeline = () => {
    if (this.state.data.length === 0) {
      return (
        <div>
          <Affix
            style={{
              position: "fixed",
              zIndex: "2",
              top: "20px",
              right: "20px",
            }}
          >
            <Tooltip placement="left" title={this.state.isRecording ? "Pause Recording" : "Record Events for Timeline"}>
              <Button
                type="primary"
                danger
                icon={this.state.isRecording ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                onClick={() => this.onRecordingClicked()}
              />
            </Tooltip>
          </Affix>
        </div>
      );
    }

    return (
      <div>
        <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
          <Panel header="Timeline" key="1" className="site-collapse-custom-panel">
            <Row>
              <Col xs={12} sm={12} md={5}>
                {this.renderEventList("All events", this.state.ids)}
              </Col>
              <Col span={19}>
                <Row style={{ paddingBottom: 30 }}>
                  <Col xs={12} sm={12} md={3}>
                    <Badge color="blue" text="Player events" style={{ fontWeight: 1 }} />
                    <Badge color="green" text="Client events" style={{ fontWeight: 1 }} />
                  </Col>
                  <Col xs={24} sm={24} md={21}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Affix
                        style={{
                          position: "fixed",
                          zIndex: "2",
                          top: "20px",
                          right: "20px",
                        }}
                      >
                        <Tooltip
                          placement="left"
                          title={this.state.isRecording ? "Pause Recording" : "Record Events for Timeline"}
                        >
                          <Button
                            type="primary"
                            danger
                            icon={this.state.isRecording ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                            onClick={() => this.onRecordingClicked()}
                          />
                        </Tooltip>
                      </Affix>
                      <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        size={"small"}
                        onClick={() => this.resetTimeline()}
                      >
                        Clear Timeline
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div
                  id="scrollableDiv"
                  style={{
                    height: HEIGHT,
                    overflow: "auto",
                    padding: "0 16px",
                  }}
                >
                  <Timeline mode="left" pending={this.state.isRecording ? "Recording..." : ""} reverse={true}>
                    {this.state.data.map((item) =>
                      this.state.ids.get(item.id) === true ? (
                        <Timeline.Item
                          label={this.formatDate(item.time)}
                          dot={this.getItemIcon(item)}
                          color={this.getItemColor(item)}
                        >
                          {item.text}
                          {item.detail !== null && (
                            <Collapse
                              ghost
                              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                              <Panel header={""} key={this.formatDate(item.time)}>
                                <JsonTree json={item.detail} />
                              </Panel>
                            </Collapse>
                          )}
                        </Timeline.Item>
                      ) : (
                        ""
                      )
                    )}
                  </Timeline>
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <br />
      </div>
    );
  };

  render() {
    return <div>{this.renderTimeline()}</div>;
  }
}

export default Timeline1;
