import React, { Component } from "react";
import { Col, Row, Statistic, Collapse, Tooltip } from "antd";
import TokenStats from "./tokenStats/TokenStatsComponent";
import SupportedStats from "./supportedStats/SupportedStatsComponent";
import AvailableQualitiesStats from "./qualityStats/AvailableQualitiesStatsComponent";
import ManifestStats from "./manifestStats/ManifestStatsComponent";
import Constants from "../../constants/Constants";
import AudioStatsComponent from "./audioStats/AudioStatsComponent";

const { PLAYER_TOOLTIPS } = Constants;
const { Panel } = Collapse;

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableVideoQualities: [],
      availableAudioQualities: [],
      bufferedPercentage: "",
      bufferedPosition: "",
      playbackPosition: "",
      aspectRatio: "",
      streamType: "",
      isLive: "",
      isClear: "",
      droppedVideoFrames: "",
      tracks: [],
    };

    // create references
    this.supportStats = React.createRef();
    this.manifestStats = React.createRef();
  }

  resetStats = () => {
    this.setState({
      availableVideoQualities: [],
      availableAudioQualities: [],
      bufferedPercentage: "",
      bufferedPosition: "",
      playbackPosition: "",
      aspectRatio: "",
      streamType: "",
      isLive: "",
      isClear: "",
      droppedVideoFrames: "",
    });

    this.manifestStats.reset();
  };

  updateStats = (object) => {
    var bufferedPercentage = Math.floor((object.bufferedRanges[0].end / object.duration) * 100);
    var bufferedPosition = object.bufferedRanges[0].end;
    var playbackPosition = object.playbackPosition;

    this.setState({
      availableVideoQualities: object.availableVideoQualities,
      availableAudioQualities: object.availableAudioQualities,
      bufferedPercentage: bufferedPercentage,
      bufferedPosition: bufferedPosition,
      playbackPosition: playbackPosition,
      resolution: object.videoData.id,
      aspectRatio: object.videoData.width + " x " + object.videoData.height,
      streamType: object.streamType.toUpperCase(),
      isLive: object.isLive,
      isClear: object.isClear,
      droppedVideoFrames: object.droppedVideoFrames,
      tracks: object.tracks,
    });

    this.manifestStats.setManifest(object.manifest);
  };

  updateStatsBeforePlaying = (object) => {
    this.supportStats.setSupportedDRMSystems(object);
  };

  getSupportedDRMSystems = () => {
    return this.supportStats.getSupportedDRMSystems();
  };

  renderArrayAsList = (title, array) => {
    if (array.length === 0) {
      // don't show anything
      return "";
    }

    return (
      <p>
        <b>{title}:</b>
        {array.map((item) => (
          <li key={item.id}>- {JSON.stringify(item)}</li>
        ))}
      </p>
    );
  };

  renderBasicStatsPanel = () => {
    if (this.state.streamType === "") {
      return "";
    }

    return (
      <Row  style={{
        marginBottom: 20,
      }}>
        <Col xs={8} sm={8} md={2}>
          <Statistic title="Stream Type" value={this.state.streamType} valueStyle={{ fontSize: 15 }} />
        </Col>
        <Col xs={8} sm={8} md={2}>
          <Statistic title="isClear" value={this.state.isClear} valueStyle={{ fontSize: 15 }} />
        </Col>
        <Col xs={8} sm={8} md={2}>
          <Statistic title="isLive" value={this.state.isLive} valueStyle={{ fontSize: 15 }} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Statistic title="Playback Position" value={this.state.playbackPosition} valueStyle={{ fontSize: 15 }} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Statistic title="Buffered Position" value={this.state.bufferedPosition} valueStyle={{ fontSize: 15 }} />
        </Col>
        <Col xs={12} sm={8} md={4}>
          <Statistic
            title="Buffered Percentage"
            value={this.state.bufferedPercentage}
            suffix="/ 100"
            valueStyle={{ fontSize: 15 }}
          />
        </Col>
        <Col xs={12} sm={8} md={4}>
          <Tooltip placement="top" title={PLAYER_TOOLTIPS.getDroppedVideoFrames}>
            <Statistic
              title="Dropped Video Frames"
              value={this.state.droppedVideoFrames}
              valueStyle={{ fontSize: 15 }}
            />
          </Tooltip>
        </Col>
        <Col xs={12} sm={8} md={2}>
          <Statistic title="Resolution" value={this.state.aspectRatio} valueStyle={{ fontSize: 15 }} />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <Collapse defaultActiveKey={["1"]} className="site-collapse-custom-collapse" size="small">
          <Panel header="Stats" key="1" className="site-collapse-custom-panel">
            {this.renderBasicStatsPanel()}
            <AvailableQualitiesStats
              video={this.state.availableVideoQualities}
              audio={this.state.availableAudioQualities}
            />
            <AudioStatsComponent tracks={this.state.tracks} />
            <ManifestStats
              ref={(instance) => {
                this.manifestStats = instance;
              }}
            />
            <TokenStats />
            <SupportedStats
              ref={(instance) => {
                this.supportStats = instance;
              }}
            />
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default Stats;
