import axios from "axios";
import { getAvsBaseUrl, getDeviceType } from "./Utility";
import { getLocalStorage, LOCAL_STORAGE_KEYS, setLocalStorage } from "./LocalStorageManager";
import {
  removeSessionStorage,
  setSessionStorage,
  getSessionStorage,
  SESSION_STORAGE_KEYS,
} from "./SessionStorageManager";
import URLConstants from "../constants/URLs";
import Constants from "../constants/Constants";

const { AVS_API_URLS } = URLConstants;
const { AVS_CONFIG } = Constants;

export const startPlaybackSession = async (index, isChannel, updateTimeline) => {
  const contentList = getLocalStorage(isChannel ? LOCAL_STORAGE_KEYS.CHANNEL_LIST : LOCAL_STORAGE_KEYS.VOD_LIST);

  if (!isNewContent(index, contentList)) {
    updateTimeline({
      type: "main",
      subtype: "warning",
      id: "PlaybackSession",
      time: Date.now(),
      text: "Warning: The content is already playing",
      detail: contentList[index],
    });
    return { result: false, error: "The content is already playing." };
  }

  const response = await getContentWithUrl(index, contentList, isChannel, updateTimeline);
  if (response.result) {
    // stop old session.
    stopOldPlaybackSession(updateTimeline).then((stoppingSessionResult) => {
      if (!stoppingSessionResult) {
        console.log("[DEBUG] - Stopping old playback session has failed. ");
      }

      // store playing content to the local storage.
      setSessionStorage(SESSION_STORAGE_KEYS.PLAYBACK_SESSION, response.content);
    });

    console.log(
      "[DEBUG] - A Playback Session has started for " +
        response.content.contentType +
        " - " +
        response.content.containerId +
        " url: " +
        response.content.url
    );

    updateTimeline({
      type: "main",
      subtype: "info",
      id: "StartPlaybackSession",
      time: Date.now(),
      text: "A Playback Session has started for " + response.content.contentType + " - " + response.content.containerId,
      detail: response.content,
    });

    return { result: true, content: response.content };
  }

  updateTimeline({
    type: "main",
    subtype: "error",
    id: "StartPlaybackSession",
    time: Date.now(),
    text: "Error: Starting a playback Session has failed",
    detail: response.detail,
  });

  return { result: false, error: response.error };
};

const isNewContent = (index, contentList) => {
  const content = contentList[index];
  const oldContent = getPlaybackSession();

  if (oldContent !== null) {
    if (content.contentType === oldContent.contentType && content.containerId === oldContent.containerId) {
      // the content is already playing..
      return false;
    }
  }
  return true;
};

export const getContentWithUrl = async (index, contentList, isChannel, updateTimeline) => {
  // check local storage first
  const content = contentList[index];
  var isOnlyToken = false;

  if (typeof content.url !== "undefined") {
    // the url has already been fetched and just need to get a token.
    isOnlyToken = true;
    console.log(
      "[DEBUG] the video url has already been fetched for this content: " +
        content.contentType +
        " - " +
        content.containerId
    );

    updateTimeline({
      type: "main",
      subtype: "info",
      id: "PlaybackSession",
      time: Date.now(),
      text: "The video url has already been fetched for " + content.contentType + " - " + content.containerId,
      detail: content,
    });
  }

  // get url from the server.
  const result = await fetchVideoURL(content, isOnlyToken, updateTimeline);

  if (result.result) {
    if (isOnlyToken) {
      if (result.token !== null) {
        content.token = result.token;
        content.time = Date.now();

        console.log(
          "[DEBUG] the token has been fetched for this content: " + content.contentType + " - " + content.containerId
        );

        updateTimeline({
          type: "main",
          subtype: "info",
          id: "PlaybackSession",
          time: Date.now(),
          text: "The token has been fetched for " + content.contentType + " - " + content.containerId,
          detail: content,
        });

        // caching the playing content for stats panel.
        setLocalStorage(LOCAL_STORAGE_KEYS.LAST_TOKEN, content);

        return { result: true, content: content };
      }
    } else {
      if (result.url !== null && result.token !== null) {
        // add new variable to the channel.
        content.url = result.url;

        // update cached content list with a content that has an url
        contentList[index] = content;

        // update the cached list
        setLocalStorage(isChannel ? LOCAL_STORAGE_KEYS.CHANNEL_LIST : LOCAL_STORAGE_KEYS.VOD_LIST, contentList);

        console.log(
          "[DEBUG] the video url and token has been fetched for this content: " +
            content.contentType +
            " - " +
            content.containerId
        );

        // add token variable but no need to store it.
        content.token = result.token;
        content.time = Date.now();

        // caching the playing content for stats panel.
        setLocalStorage(LOCAL_STORAGE_KEYS.LAST_TOKEN, content);

        updateTimeline({
          type: "main",
          subtype: "info",
          id: "PlaybackSession",
          time: Date.now(),
          text: "The video url and token has been fetched for " + content.contentType + " - " + content.containerId,
          detail: content,
        });

        return { result: true, content: content };
      }
    }
    return { result: false, error: "response is invalid.", detail: result.detail };
  }
  return { result: false, error: result.error, detail: result.detail };
};

const fetchVideoURL = async (content, isOnlyToken) => {
  // cached data
  const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
  const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);
  const device_id = getLocalStorage(LOCAL_STORAGE_KEYS.DEVICE_ID);

  let url = getAvsBaseUrl(environment) + AVS_API_URLS.VIDEO_URL;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());
  url = url.replace("$PROPERTY", AVS_CONFIG.PROPERTY_NAME);
  url = url.replace("$CONTENT_TYPE", content.contentType);
  url = url.replace("$CONTENT_ID", content.containerId);
  url = url.replace("$ASSET_ID", content.assetId);
  url = url.replace("$IS_ONLY_TOKEN", isOnlyToken);
  url = url.replace("$DEVICE_ID", device_id);

  // {"url": http://localhost:8010/proxy/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/CONTENT/VIDEOURL/LIVE/622/10
  // sample: https://telus.prod.g.svc.tv.telus.net/TELUS/1.5/R/ENG/ANDROID_TV_STB/OPTIK/CONTENT/VIDEOURL/LIVE/24/10?onlyToken=false&deviceId=fd5030d3ac61d2c9
  const result = await axios({
    url: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Avs-Refresh-Token": avs_refresh_token,
    },
    withCredentials: true,
  })
    .then((response) => {
      //console.log("fetchVideoURL response: " + JSON.stringify(response));
      if (response.data.resultCode === "OK") {
        if (isOnlyToken) {
          return {
            result: true,
            token: response.data.resultObj.token,
          };
        }

        return {
          result: true,
          url: response.data.resultObj.src,
          token: response.data.resultObj.token,
        };
      } else if (response.data.resultCode === "KO") {
        return { result: false, error: response.data.message, detail: response.data };
      }

      return { result: false, error: "Failed to fetch video URL." };
    })
    .catch((error) => {
      console.log(" fetchVideoURL action has been failed: " + JSON.stringify(error));

      return { result: false, error: error.message, detail: error };
    });

  return result;
};

export const getPlaybackSession = () => {
  return getSessionStorage(SESSION_STORAGE_KEYS.PLAYBACK_SESSION);
};

export const stopOldPlaybackSession = async (updateTimeline) => {
  const oldContent = getPlaybackSession();

  if (oldContent === null) {
    return true;
  }

  // that means there is a on going session that needs to be stopped.
  const result = await stopPlaybackSession(oldContent);
  if (result.result) {
    removeSessionStorage(SESSION_STORAGE_KEYS.PLAYBACK_SESSION);
    console.log(
      "[DEBUG] - The playback session has been stopped for " + oldContent.contentType + " - " + oldContent.containerId
    );

    updateTimeline({
      type: "main",
      subtype: "info",
      id: "StopPlaybackSession",
      time: Date.now(),
      text: "The playback session has stopped for " + oldContent.contentType + " - " + oldContent.containerId,
      detail: oldContent,
    });
    return true;
  }

  console.log("[DEBUG] - Stopping old playback session has failed : " + result.error);

  updateTimeline({
    type: "main",
    subtype: "error",
    id: "StopPlaybackSession",
    time: Date.now(),
    text: "Error: Stopping old playback session has failed",
    detail: result.error,
  });
  return false;
};

/**
 * Function to make the Stop Content call for AVS backend. If current content is VOD,
 * save bookmark info too.
 */
const stopPlaybackSession = async (content) => {
  // cached data
  const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
  const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);

  if (content.containerId === null) {
    return { result: false, error: "container id or contentType is null." };
  }

  let url = getAvsBaseUrl(environment) + AVS_API_URLS.STOP_PLACKBACK_SESSION;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());
  url = url.replace("$PROPERTY", AVS_CONFIG.PROPERTY_NAME);
  url = url.replace("$CONTENT_TYPE", content.contentType);
  url = url.replace("$CONTENT_ID", content.containerId);

  var bookmark = null;
  if (content.contentType === "VOD") {
    bookmark = {
      startDeltaTime: 0, // TODO::
      isComplete: false, // TODO::
    };
  }

  // This is specific to cPVR where we're required to pass the externalContentId to bookmark call
  if (content.contentType === "RECORDING") {
    bookmark.externalContentId = content.externalId;
  }

  // sample: http://localhost:8010/proxy/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/CONTENT/USERDATA/LIVE/91
  const result = await axios({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Avs-Refresh-Token": avs_refresh_token,
    },
    data: { deltaThreshold: 0, bookmark: bookmark },
    withCredentials: true,
  })
    .then((response) => {
      //console.log("stopPlaybackSession response: " + JSON.stringify(response));
      if (response.data.resultCode === "OK") {
        return {
          result: true,
        };
      } else if (response.data.resultCode === "KO") {
        return { result: false, error: response.data.message };
      }

      return { result: false };
    })
    .catch((error) => {
      console.log("[DEBUG] - StopPlaybackSession action has been failed: " + JSON.stringify(error));

      return { result: false, error: error };
    });

  return result;
};
