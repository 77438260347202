export const SESSION_STORAGE_KEYS = {
  PLAYBACK_SESSION: "playback_session",
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key) => {
  var item = sessionStorage.getItem(key);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

export const removeSessionStorage = (key) => {
  // remove
  sessionStorage.removeItem(key);
};

export const clearSessionStorages = () => {
  sessionStorage.clear();
  console.log("[DEBUG] clearSessionStorages has been called.");
};

//The difference is that with sessionStorage, the data is persisted only until the window or tab is closed. With localStorage, the data is persisted until the user manually clears the browser cache or until your web app clears the data.
