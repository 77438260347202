/**
 * Measures the distance to Live Edge
 */
export const getLiveEdge = (player) => {
  let maxBuffer = Math.max(
    player.buffer.getLevel("forwardduration", "video").targetLevel,
    player.buffer.getLevel("forwardduration", "audio").targetLevel
  );
  return -player.getTimeShift() + maxBuffer;
};

/**
 * Tries to get segment number from url.
 * For example
 * it returns 16619435148946776 for this dash stream: "https://da-d87bc2942001056a80020c0d0000000000000148.id.cdn.telus.com/wp/lott-web-opus.cdn.avp.telus.net/232006003096/vxfmt=dp/h_3b31092c08faa6613b37e2536994236ae0_video_0_5260000_t16619435148946776.mp4?device_profile=dash_cenc_verimatrix%26seg_size=
 * it returns 420420000 for this VOD content: "https://da-d87bc2a42001056a80020c0e0000000000000164.id.cdn.telus.com/wp/vod-web-opus.cdn.avp.telus.net/sdash/fd1e88cc-08d5-4c8f-b106-0d44d716f58f/index.mpd/S!d2EMVk1YX0RBU0hfQUxMEgdU.v...wE24Um3nw__/QualityLevels(4670416)/Fragments(video=420420000)"
 * it returns 35058650 for HLS content https://da-ccbf04322001056808010e060000000000000050.id.lab.cdn.telus.net/wp/vxoalottdevopus.cdn.avp.teluslabs.net/232008200017c/vxfmt=dp/h_f78a567c4fdbd05fb3077f359c2e2f87e0/var2530000/vid/seg35058650_w1661958301.ts?device_profile=hls
 * TODO: update whenever the URL format has changed.
 */
export const getSegment = (url) => {
  return -1;
  // check live stream url first
  let index = url.indexOf(".mp4");
  if (index > 0) {
    return url.substring(index - 17, index);
  } else {
    // check vod stream url
    let searchKey = "Fragments(video=";
    index = url.indexOf(searchKey);
    if (index > 0) {
      return url.substring(index + searchKey.length, url.length - 1);
    } else {
      // check vod stream url
      searchKey = "/vid/seg";
      index = url.indexOf(searchKey);

      if (index > 0) {
        let string = url.substring(index + searchKey.length, url.length);
        let end = string.indexOf("_");
        return string.substring(0, end);
      }
    }
  }
  return index;
};

/**
 * finds the right bitrate id considering both the bitrate value suggested by the player and the slider range set by the user.
 */
export const getRightBitrateId = (event, sliderRange) => {
  if (sliderRange.length > 0) {
    if (sliderRange.length === 1) {
      // if there is only bitrate value that is suggested by the user, choose it.
      return sliderRange[0];
    } else if (sliderRange.includes(event.suggested)) {
      // if there suggested bitrate in the list return the the suggested one
      return event.suggested;
    } else {
      const availableVideoQualities = event.representations.sort((a, b) => (a.bandwidth > b.bandwidth ? 1 : -1));
      var suggestedIndex = -1;
      var minIndex = -1;

      availableVideoQualities.forEach((item, index) => {
        if (item.id === event.suggested) {
          suggestedIndex = index;
        } else if (item.id === sliderRange[0]) {
          minIndex = index;
        }
      });

      if (minIndex > suggestedIndex) {
        return sliderRange[0];
      }
      // choose the nearest bitrate
      return sliderRange[sliderRange.length - 1];
    }
  }

  // select suggested bitrate first.
  return event.suggested;
};

export const isSuitableForLowLatency = (latency, manifest, streamType) => {
  if (latency === Infinity) {
    return false;
  }

  // HLS CMAF Low Latency is not supported by Web by the player, only DASH.
  // source: https://bitmovin.com/docs/player/faqs/does-bitmovin-player-support-low-latency-streaming
  if (streamType === "dash") {
    let position = manifest.search("availabilityTimeOffset");
    if (position > 0) {
      return true;
    }
  }

  return false;
};

/**
 * Error messages that are defined by the Player.
 * Copied from https://github.com/bitmovin/bitmovin-player-ui/blob/fab606809c4c418c37adebdde7f9704442f1b04d/src/ts/errorutils.ts
 */
const PLAYER_ERROR_MESSAGES_ARRAY = [
  [1000, "Error is unknown"],
  [1001, "The player API is not available after a call to PlayerAPI.destroy."],
  [1100, "General setup error"],
  [1101, "There was an error when inserting the HTML video element"],
  [1102, "No configuration was provided"],
  [1103, "The license is not valid"],
  [1104, "The the domain-locked player is not authorized to playback on this domain"],
  [1105, "The domain is not allowlisted"],
  [1106, "The license server URL is invalid"],
  [1107, "The impression server URL is invalid"],
  [1108, "Could not initialize a rendering engine"],
  [1109, "The used flash version does not support playback"],
  [1110, "Native Flash is not authorized by a valid Adobe token"],
  [1111, "Flash doesn't have sufficient resources"],
  [1112, "Flash container API not available"],
  [
    1113,
    'Protocol not supported. This site has been loaded using "file" protocol, but unfortunately this is not supported. Please load the page using a web server (using http or https)',
  ],
  [1200, "General source error"],
  [1201, "Codec or file format in the manifest is not supported"],
  [1202, "The downloaded manifest is invalid"],
  [1203, "There was no technology detected to playback the provided source"],
  [1204, "The stream type is not supported"],
  [1205, "The forced technology is not supported"],
  [1206, "No stream found for supported technologies."],
  [1207, "The downloaded segment is empty"],
  [1208, "The manifest could not be loaded"],
  [1209, "Progressive stream type not supported or the stream has an error"],
  [1210, "HLS stream has an error"],
  [1211, "The encryption method is not supported"],
  [1300, "General playback error"],
  [1301, "Video decoder or demuxer had an error with the content"],
  [1302, "General error if Flash renderer has an error"],
  [1303, "Flash doesn't have sufficient resources"],
  [1304, "The transmuxer could not be initialized"],
  [1400, "Network error while downloading"],
  [1401, "The manifest download timed out"],
  [1402, "The segment download timed out"],
  [1403, "The progressive stream download timed out"],
  [1404, "The Certificate could not be loaded"],
  [2000, "General DRM error"],
  [2001, "Required DRM configuration is missing"],
  [2002, "The licensing server URL is missing. (Could not decrypt segment)"],
  [2003, "The DRM license renewal failed"],
  [2004, "Key or KeyId is missing"],
  [2005, "Key size is not supported"],
  [2006, "Unable to instantiate a key system supporting the required combinations"],
  [2007, "Unable to create or initialize key session"],
  [2008, "The MediaKey object could not be created/initialized"],
  [2009, "Key error"],
  [2010, "The key system is not supported"],
  [2011, "The certificate is not valid"],
  [2012, "Invalid header key/value pair for PlayReady license request"],
  [2013, "Content cannot be played back because the output is restricted on this machine"],
  [2014, "DRM error for the Flash renderer"],
  [2100, "General VR error"],
  [2101, "Player technology not compatible with VR playback"],
  [3000, "General module error"],
  [3001, "The definition of the module is invalid (e.g. incomplete)."],
  [
    3002,
    "The module definition specifies dependencies but the module is not provided via a function for deferred loading.",
  ],
  [3003, "A module cannot be loaded because it has not been added to the player core."],
  [3004, "A module cannot be loaded because one or more dependencies are missing."],
  [3100, "An Advertising module error has occurred. Refer to the attached AdvertisingError."],
];

export const PLAYER_ERROR_MESSAGES = new Map(PLAYER_ERROR_MESSAGES_ARRAY);
