import React, { Component } from "react";
import { List, message } from "antd";

class ListTab extends Component {
  onClick = (index) => {
    //console.log("onClick has been called " + index);
  };

  createListSource = () => {
    // create an array for tree data.
    var array = [];

    // return empty array
    return array;
  };

  showErrorMessage = (error) => {
    message.error(error);
  };

  renderListItem = (item) => {
    return (
      <List.Item
        onClick={() => {
          this.onClick(item.index);
        }}
        style={
          this.props.selectedItem !== null &&
          this.props.selectedItem.tab === this.props.tab &&
          this.props.selectedItem.index === item.index
            ? { backgroundColor: "#61A2D4" }
            : {}
        }
      >
        <List.Item.Meta title={item.title} description={item.description} />
      </List.Item>
    );
  };

  render() {
    const source = this.createListSource();
    return (
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <List
          loading={source.length === 0 ? true : false}
          itemLayout="horizontal"
          dataSource={source}
          renderItem={this.renderListItem}
        />
      </div>
    );
  }
}

export default ListTab;
