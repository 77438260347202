export const CONTENT_TYPES = [
  { id: 101, title: "Dash" },
  { id: 102, title: "HLS" },
  { id: 103, title: "Smooth" },
];

export const DRM_TYPES = [
  { id: 301, title: "widevine" },
  { id: 302, title: "playready" },
  { id: 303, title: "primetime" },
  { id: 304, title: "fairplay" },
];

export const CONTENTS = [
  {
    content_type: 102,
    title: "Redbull m3u8",
    url: "https://bitmovin-a.akamaihd.net/content/sintel/hls/playlist.m3u8",
    poster: "https://images-na.ssl-images-amazon.com/images/I/51RZhVktm2L._AC_SL1035_.jpg",
  },
  {
    content_type: 101,
    title: "Sintel mpd - subtitles and audios",
    url: "http://bitdash-a.akamaihd.net/content/sintel/sintel.mpd",
    poster: "https://lh3.googleusercontent.com/J9rHw7X7uh1ZiIuW7WGooTRVRiUd0iv58-HcsC-s9mr57nUHGwZuCnmIluoQ4qTv_g",
  },
  {
    content_type: 101,
    title: "Live AKAMAI Clear CMAF (Single Rate)",
    url: "https://akamaibroadcasteruseast.akamaized.net/cmaf/live/657078/akasource/out.mpd",
  },
  {
    content_type: 101,
    title: "Live AKAMAI Clear CMAF (Multi-Rate)",
    url: "https://cmafref.akamaized.net/cmaf/live-ull/2006350/akambr/out.mpd",
  },
  {
    content_type: 101,
    title: "Live Clear CMAF 2",
    url: "https://livesim.dashif.org/livesim/testpic_2s/multi_subs.mpd",
  },
  {
    content_type: 101,
    title: "Live DASHIF Clear CMAF 3 (Single Rate)",
    url: "https://livesim.dashif.org/livesim/chunkdur_1/ato_7/testpic4_8s/Manifest300.mpd",
  },
  {
    content_type: 101,
    title: "Live DASHIF Clear CMAF 4 (Multi-Rate)",
    url: "https://livesim.dashif.org/livesim/chunkdur_1/ato_7/testpic4_8s/Manifest300.mpd",
  },
  {
    content_type: 101,
    title: "Live DASHIF Clear CMAF 5",
    url: "  https://livesim.dashif.org/livesim/scte35_2/testpic_2s/Manifest.mpd",
  },
  {
    content_type: 101,
    title: "Live AWS Clear CMAF 6",
    url: "https://d24rwxnt7vw9qb.cloudfront.net/v1/dash/e6d234965645b411ad572802b6c9d5a10799c9c1/All_Reference_Streams/4577dca5f8a44756875ab5cc913cd1f1/index.mpd",
  },
  {
    content_type: 102,
    title: "Live Clear 1",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200017c/vxfmt=dp/playlist.m3u8?device_profile=hlsclr",
  },
  {
    content_type: 102,
    title: "Live Clear 2",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200018c/vxfmt=dp/playlist.m3u8?device_profile=hlsclr",
  },
  {
    content_type: 102,
    title: "Live Clear 3",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200019c/vxfmt=dp/playlist.m3u8?device_profile=hlsclr",
  },
  {
    content_type: 101,
    title: "Live Clear 4",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200020c/vxfmt=dp/manifest.mpd?device_profile=dashclr",
  },
  {
    content_type: 101,
    title: "Live Clear 5",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200017c/vxfmt=dp/manifest.mpd?device_profile=dashclr",
  },

  {
    content_type: 101,
    title: "Live Clear 6",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200018c/vxfmt=dp/manifest.mpd?device_profile=dashclr",
  },
  {
    content_type: 101,
    title: "Live Clear 7",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200019c/vxfmt=dp/manifest.mpd?device_profile=dashclr",
  },
  {
    content_type: 102,
    title: "Live Clear HLS 4K",
    url: "http://vxteamtest.cdn.avp.teluslabs.net/232008200251/vxfmt=dp/playlist.m3u8?device_profile=hlsvmxfpscmaf",
  },
  {
    content_type: 101,
    title: "Live Widevine 1",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200002/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200002",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 2",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200003/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200003",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 3",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200004/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200004",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 4",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200007/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200007",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 5",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200010/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200010",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 6",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200013/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200013",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Live Widevine 7",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200014/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "232008200014",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Bitmovin DRM mpd",
    url: "https://bitmovin-a.akamaihd.net/content/art-of-motion_drm/mpds/11331.mpd",
    poster: "https://www.greece-is.com/wp-content/uploads/2016/10/BLOG_RED_BULL_01.jpg",
    license_url: "https://cwip-shaka-proxy.appspot.com/no_auth",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "Google Widevine DRM mpd 1",
    url: "https://storage.googleapis.com/shaka-demo-assets/angel-one-widevine/dash.mpd",
    license_type: 301,
    license_url: "https://cwip-shaka-proxy.appspot.com/no_auth",
  },
  {
    content_type: 102,
    title: "AVP iOS Test APP DRM demo",
    url: "https://vxoalottdevopus.cdn.avp.teluslabs.net/232008200014/vxfmt=dp/playlist.m3u8?device_profile=hlsvmxfps",
    license_type: 304,
    license_url: "https://multidrm.core.verimatrixcloud.net/fairplay",
    license_cert_url: "https://optik-universal-links.watchtelustv.com/fairplay.cer",
    subject: "232008200014",
  },
  {
    content_type: 102,
    title: "VOD Clear HLS",
    url: "  http://sample.vodobox.com/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8    ",
  },
  {
    content_type: 102,
    title: "VOD Clear CMAF H264 HLS",
    url: "https://s3.eu-central-1.amazonaws.com/com.veeplay.samples/cmaf/sintel-h264.m3u8",
  },
  {
    content_type: 101,
    title: "VOD Clear CMAF HEVC DASH",
    url: "https://s3.eu-central-1.amazonaws.com/com.veeplay.samples/cmaf-hevc/Sintel.2010.4k.mpd",
  },
  {
    content_type: 103,
    title: "VOD CLEAR H264 SMOOTH 1",
    url: "https://amssamples.streaming.mediaservices.windows.net/683f7e47-bd83-4427-b0a3-26a6c4547782/BigBuckBunny.ism/manifest",
  },
  {
    content_type: 103,
    title: "AUDIO ONLY CLEAR SMOOTH 1",
    url: "https://playready.directtaps.net/smoothstreaming/ISMAAACLC/Taxi3_AACLC.ism/Manifest",
  },
  {
    content_type: 103,
    title: "VOD DRM H264 SMOOTH 1",
    url: "https://profficialsite.origin.mediaservices.windows.net/09e36702-8f33-436c-a5dd-60ffe6671e70/SuperSpeedway_720PR.ism/manifest",
    license_url: "https://test.playready.microsoft.com/service/rightsmanager.asmx?cfg=(persist:false,sl:150)",
    license_type: 302,
  },
  {
    content_type: 101,
    title: "VOD DRM 1",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/8ee6447a-c199-4e3b-8349-dacc95c13d08/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "8ee6447a-c199-4e3b-8349-dacc95c13d08",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 2",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/28489c46-436c-4175-ad2f-3d052b2ae87f/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "28489c46-436c-4175-ad2f-3d052b2ae87f",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 3",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/VOD_ATP_NEW_01/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "VOD_ATP_NEW_01",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 4",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/3149bb97-c89d-4c4d-97b1-61526cf55432/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "3149bb97-c89d-4c4d-97b1-61526cf55432",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 5",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/6b56593d-469a-4e49-83c8-41f8e85cf12f/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "6b56593d-469a-4e49-83c8-41f8e85cf12f",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 6",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/ade2589b-55d6-4bb8-ab89-f808adda993e/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "ade2589b-55d6-4bb8-ab89-f808adda993e",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM 7",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/2b1b872a-0957-4450-9d9d-17115a56a28b/index.mpd/Manifest?device=VMX_CORE_DASH",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "2b1b872a-0957-4450-9d9d-17115a56a28b",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM Restrict 1",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/3149bb97-c89d-4c4d-97b1-61526cf55432/index.mpd/Manifest?device=VMX_DASH_RESTRICT",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "3149bb97-c89d-4c4d-97b1-61526cf55432",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM Restrict 2",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/dup_bdf6cf10-c105-4c7a-b912-2b6af64563e9/index.mpd/Manifest?device=VMX_DASH_RESTRICT",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "dup_bdf6cf10-c105-4c7a-b912-2b6af64563e9",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD DRM Restrict 3",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/28489c46-436c-4175-ad2f-3d052b2ae87f/index.mpd/Manifest?device=VMX_DASH_RESTRICT",
    license_url: "https://multidrm.core.verimatrixcloud.net/widevine",
    subject: "28489c46-436c-4175-ad2f-3d052b2ae87f",
    license_type: 301,
  },
  {
    content_type: 101,
    title: "VOD Clear 1",
    url: "http://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/ATP_605_T1/index.mpd/Manifest?device=DASH3",
  },
  {
    content_type: 101,
    title: "VOD Clear 2",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/28489c46-436c-4175-ad2f-3d052b2ae87f/index.mpd/Manifest?device=DASH3",
  },
  {
    content_type: 101,
    title: "VOD Clear 3",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/VOD_ATP_NEW_01/index.mpd/Manifest?device=DASH3",
  },
  {
    content_type: 101,
    title: "VOD Clear 4",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/3149bb97-c89d-4c4d-97b1-61526cf55432/index.mpd/Manifest?device=DASH3",
  },
  {
    content_type: 101,
    title: "VOD Clear 5",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/6b56593d-469a-4e49-83c8-41f8e85cf12f/index.mpd/Manifest?device=DASH3",
  },
  {
    content_type: 101,
    title: "VOD Clear 6",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/ade2589b-55d6-4bb8-ab89-f808adda993e/index.mpd/Manifest?device=DASH_ALL",
  },
  {
    content_type: 101,
    title: "VOD Clear 7",
    url: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/2b1b872a-0957-4450-9d9d-17115a56a28b/index.mpd/Manifest?device=DASH_ALL",
  },
  {
    content_type: 101,
    title: "VSPP_Clear_ProdC_1",
    url: "http://vod.origin.prodc.telus.net/sdash/1d2f48a4-dec6-45d0-aed3-ebd1689d2c94/index.mpd/Manifest?device=DASH2",
  },
  {
    content_type: 101,
    title: "VSPP_Clear_ProdC_2",
    url: "http://vod.origin.prodc.telus.net/sdash/00fd1729-0dcc-46a0-b568-ecfbd6738cd0/index.mpd/Manifest?device=DASH2",
  },
  {
    content_type: 101,
    title: "VSPP_Clear_ProdC_3",
    url: "http://vod.origin.prodc.telus.net/sdash/5af06045-665b-4547-b240-730a471fb99f/index.mpd/Manifest?device=DASH2",
  },
  {
    content_type: 101,
    title: "VSPP_Clear_ProdC_4",
    url: "http://vod.origin.prodc.telus.net/sdash/5af06045-665b-4547-b240-730a471fb99f/index.mpd/Manifest?device=DASH2",
  },
  {
    content_type: 101,
    title: "VSPP_Clear_ProdC_5",
    url: "http://vod.origin.prodc.telus.net/sdash/e86579dc-0120-485d-90ba-6655375a64cb/index.mpd/Manifest?device=DASH2",
  },
  {
    content_type: 101,
    title: "Tears DRM mpd",
    url: "https://storage.googleapis.com/wvmedia/cenc/h264/tears/tears.mpd",
    poster: "https://www.greece-is.com/wp-content/uploads/2016/10/BLOG_RED_BULL_01.jpg",
    license_url: "https://proxy.staging.widevine.com/proxy",
    license_type: 301,
  },
];
