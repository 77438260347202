import React, { Component } from "react";
import { Tabs, Collapse } from "antd";
import JsonTree from "../../common/JsonTreeComponent";
import xml2js from "xml2js";
import { Parser as M3u8Parser } from "m3u8-parser";

const { Panel } = Collapse;

class ManifestStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: "",
      isXML: false,
      manifest: "",
      manifestJSON: "",
    };
  }

  setManifest = (manifest) => {
    const isXML = manifest.search("<MPD") === 0 ? true : manifest.search("<?xml") !== -1;
    this.setState({ manifest: manifest, isXML: isXML });

    if (this.state.manifestJSON === "") {
      if (isXML) {
        // convert XML to JSON
        xml2js.parseString(manifest, (err, result) => {
          this.setState({ manifestJSON: result });
        });
      } else {
        const parser = new M3u8Parser();

        parser.push(manifest);
        parser.end();

        this.setState({ manifestJSON: parser.manifest });
      }
    }
  };

  reset = () => {
    this.setState({
      activeTabKey: "",
      manifest: "",
      manifestJSON: "",
    });
  };

  onTabChange = (selectedTabKey) => {
    this.setState({ activeTabKey: selectedTabKey });
  };

  onChange = (key: string) => {
    console.log(key);
  };

  getTabs = () => {
    return [
      {
        key: "text",
        label: "Text",
        children: <div>{this.state.manifest}</div>,
      },
      {
        key: "tree",
        label: "Tree",
        children: <JsonTree json={this.state.manifestJSON} expendAll={true} />,
      },
    ];
  };

  render() {
    if (this.state.manifest === "") {
      // don't show anything
      return "";
    }

    return (
      <div>
        <Collapse defaultActiveKey="stats-manifest">
          <Panel header="Manifest" key="stats-manifest">
            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: "auto",
                padding: "0 0px",
              }}
            >
              <Tabs defaultActiveKey={"text"} tabPosition="left" size="small" items={this.getTabs()} />
            </div>
          </Panel>
        </Collapse>
        <br />
      </div>
    );
  }
}

export default ManifestStats;
