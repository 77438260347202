import ListTab from "../ListTabComponent";
import { getChannels } from "./ChannelListHelper";
import { contentToPlayerSource } from "../../../utils/Utility";
import { startPlaybackSession } from "../../../utils/PlaybackSessionManager";
import { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";

class ChannelListTab extends ListTab {
  isFetching = false;

  getChannelsAndStore = () => {
    if (this.isFetching) {
      return;
    }
    this.isFetching = true;

    // get channels
    getChannels().then((channelResult) => {
      // check response
      if (channelResult.result && channelResult.channels !== null) {
        // save channels on local storage
        setLocalStorage(LOCAL_STORAGE_KEYS.CHANNEL_LIST, channelResult.channels);

        console.log(
          "[DEBUG] a channel list has been cached - size: " + getLocalStorage(LOCAL_STORAGE_KEYS.CHANNEL_LIST).length
        );
        // recall render again to populate cached channel list.
        this.forceUpdate();
      } else {
        this.showErrorMessage(channelResult.error);
      }
    });
  };

  createListSource = () => {
    const oldChannelList = getLocalStorage(LOCAL_STORAGE_KEYS.CHANNEL_LIST);

    if (oldChannelList === null) {
      this.getChannelsAndStore();
      // return empty list for now.
      return [];
    }

    // create a new list for ListTab
    const channelList = [];
    oldChannelList.forEach((channel, index) => {
      const title = channel.channelNumber + " - " + channel.channelName;
      const description = channel.contentType + " - Container id: " + channel.containerId + " - Asset id: " + channel.assetId;

      // since we don't know content_type and url value yet
      channelList.push({
        index: index,
        title: title,
        description: description,
      });
    });

    return channelList;
  };

  onClick = async (index) => {
    console.log("[DEBUG] onClick has been called for index: " + index);

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "ChannelListOnClick",
      time: Date.now(),
      text: "onClick action has performed for ChannelList - index: " + index,
      detail: null,
    });

    const response = await startPlaybackSession(index, true, this.props.updateTimeline);

    if (response.result) {
      // convert channel to the player source.
      const source = contentToPlayerSource(response.content);
      // notify parent component.
      this.props.onContentSelected({ index: index, tab: this.props.tab }, source);
    } else {
      // display error message on screen.
      this.showErrorMessage(response.error);
    }
  };
}

export default ChannelListTab;
