import axios from "axios";
import { getAvsBaseUrl, getDeviceType } from "../../../utils/Utility";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";
import URLConstants from "../../../constants/URLs";
import Constants from "../../../constants/Constants";

const { AVS_API_URLS } = URLConstants;
const { AVS_CONFIG } = Constants;

export const getChannels = async () => {
  // get params from local storage
  const environment = getLocalStorage(LOCAL_STORAGE_KEYS.ENVIRONMENT);
  const avs_refresh_token = getLocalStorage(LOCAL_STORAGE_KEYS.AVS_REFRESH_TOKEN);
  const regionId = getLocalStorage(LOCAL_STORAGE_KEYS.REGION_ID);
  const live_package_ids = getLocalStorage(LOCAL_STORAGE_KEYS.LIVE_PACKAGE_IDS);

  // query params
  const orderBy = "orderId";
  const sortOrder = "asc";

  let url = getAvsBaseUrl(environment) + AVS_API_URLS.CHANNEL;
  url = url.replace("$AGL_VERSION", AVS_CONFIG.APP_VERSION);
  url = url.replace("$LANG", AVS_CONFIG.LANGUAGE);
  url = url.replace("$PLATFORM", getDeviceType());
  url = url.replace("$PROPERTY", AVS_CONFIG.PROPERTY_NAME);
  url = url.replace("$ORDER_BY", orderBy);
  url = url.replace("$SORT_ORDER", sortOrder);
  url = url.replace("$LIVE_PACKAGE_IDS", live_package_ids);
  url = url.replace("$REGION_ID", regionId);

  console.log("url : " + url);
  // https://telus.prod.g.svc.tv.telus.net/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/TRAY/LIVECHANNELS?orderBy=orderId&sortOrder=asc&filter_technicalPackages=88&regionId=undefined
  // sample: https://telus.prod.g.svc.tv.telus.net/TELUS/1.5/R/ENG/CHROME_FIREFOX_HTML5/OPTIK/TRAY/LIVECHANNELS?orderBy=orderId&sortOrder=asc&filter_technicalPackages=75800%2C75803%2C75806%2C75809%2C75812%2C75815%2C75818%2C75821%2C75824%2C75827%2C75830%2C75833%2C75836%2C75839%2C75842%2C75845%2C75848%2C75851%2C75857%2C75860%2C75863%2C75866%2C75869%2C75872%2C75875%2C75878%2C75881%2C75884%2C75887%2C75890%2C75893%2C75896%2C75899%2C75902%2C75905%2C75908%2C75911%2C75914%2C75917%2C75920%2C75923%2C75926%2C75929%2C75932%2C75935%2C75938%2C75941%2C75944%2C75947%2C75950%2C75953%2C75956%2C75959%2C75962%2C75965%2C75968%2C75971%2C75974%2C75977%2C75980%2C75983%2C75986%2C75989%2C75992%2C75995%2C75998%2C76001%2C76004%2C76007%2C76010%2C76013%2C76016%2C76019%2C76022%2C76025%2C76028%2C76031%2C76034%2C76037%2C76040%2C76043%2C76046%2C76049%2C76052%2C76055%2C76058%2C76061%2C76064%2C76067%2C76070%2C76073%2C76076%2C76079%2C76082%2C76085%2C76088%2C76091%2C76094%2C76097%2C76100&regionId=88
  const result = await axios({
    url: url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Avs-Refresh-Token": avs_refresh_token,
    },
    withCredentials: true,
  })
    .then((response) => {
      //console.log("getChannels action result: " + JSON.stringify(response));

      if (response.data.resultCode === "OK") {
        // parse containers
        const channels = parseChannelContainers(response.data.resultObj.containers, regionId);

        return { result: true, channels: channels };
      } else if (response.data.resultCode === "KO") {
        return { result: false, channels: null, error: response.data.message };
      }

      return {
        result: false,
        channels: null,
        error: "Failed to fetch channels",
      };
    })
    .catch((error) => {
      console.log(" getChannels action has been failed: " + JSON.stringify(error));

      return { result: false, error: error };
    });

  return result;
};

const getChannelNumber = (regions, region_id) => {
  for (const region of regions) {
    if (region.regionId === parseInt(region_id)) {
      return region.regionalChannelNumber;
    }
  }
  return null;
};

const parseChannelContainers = (containers, region_id) => {
  var channels = [];

  containers.forEach((item) => {
    const containerId = item.id;
    const channelNumber = getChannelNumber(item.regions, region_id);
    const contentType = item.metadata.contentType;
    const channelName = item.metadata.channelName;
    const asset = item.assets[0];
    const assetType = asset.assetType;
    const assetId = asset.assetId;

    if (assetType === "MASTER") {
      channels.push({
        containerId: containerId,
        contentType: contentType,
        channelNumber: channelNumber,
        channelName: channelName,
        assetId: assetId,
      });
    }
  });

  return channels;
};
