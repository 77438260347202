import React, { Component } from "react";
import { Divider, List, message } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { contentToPlayerSource } from "../../../utils/Utility";
import { getVODs } from "./VodListHelper";
import { startPlaybackSession } from "../../../utils/PlaybackSessionManager";
import { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../utils/LocalStorageManager";

// constants
const ITEM_COUNT = 100;

class VodListTab extends Component {
  isLoading = false;

  getVodListAndStore = () => {
    if (this.isLoading) {
      return;
    }

    setLocalStorage(LOCAL_STORAGE_KEYS.HAS_MORE_VOD_LIST, true);
    this.isLoading = true;

    var page = getLocalStorage(LOCAL_STORAGE_KEYS.VOD_LIST_TAB_PAGE);

    if (page === null) {
      page = 0;
    } else {
      page = parseInt(page);
    }

    // prepare parameters
    const from = page * ITEM_COUNT;
    const to = ITEM_COUNT * (page + 1) - 1;
    const maxResults = to + 1;

    console.log("[DEBUG] fetching VOD contents from " + from + " to: " + to + " page: " + page);

    // get channels
    getVODs(from, to, maxResults).then((vodResult) => {
      // check response
      if (vodResult.result && vodResult.vodList.length > 0) {
        var vodList = getLocalStorage(LOCAL_STORAGE_KEYS.VOD_LIST);

        if (vodList) {
          // update the list
          vodList.push(...vodResult.vodList);
          console.log("[DEBUG] the cached vod list has been updated - the new size: " + vodList.length);
        } else {
          // create a new cache list
          vodList = vodResult.vodList;
          console.log("[DEBUG] a vod list has been cached - size: " + vodList.length);
        }

        // save channels on local storage
        setLocalStorage(LOCAL_STORAGE_KEYS.VOD_LIST, vodList);

        // increase page value for the next request.
        page++;
        // store it on local storage
        setLocalStorage(LOCAL_STORAGE_KEYS.VOD_LIST_TAB_PAGE, page);

        // recall render again to populate cached channel list.
        this.forceUpdate();
      } else {
        if (vodResult.hasMore === false) {
          setLocalStorage(LOCAL_STORAGE_KEYS.HAS_MORE_VOD_LIST, false);

          // recall render again to show end message.
          this.forceUpdate();
        } else {
          // show up the error message on screen.
          message.error(vodResult.error);
        }
      }

      // this.page++;
      this.isLoading = false;
    });
  };

  createListSource = () => {
    // console.log("createListSource has been called.");

    const oldVodList = getLocalStorage(LOCAL_STORAGE_KEYS.VOD_LIST);

    if (oldVodList === null) {
      // fetch the VOD List asynchronously
      this.getVodListAndStore();
      return [];
    }

    // create a new list for ListTab
    const vodList = [];
    oldVodList.forEach((vod, index) => {
      const title = vod.title;
      const description = vod.contentType + " - Container id: " + vod.containerId + " - Asset id: " + vod.assetId;

      // since we don't know content_type and url value yet
      vodList.push({
        index: index,
        title: title,
        description: description,
      });
    });

    return vodList;
  };

  onClick = async (index) => {
    // console.log("onClick has been called for index: " + index + " " + localStorage.getItem(STORAGE_KEY));

    this.props.updateTimeline({
      type: "main",
      subtype: "info",
      id: "VODListOnClick",
      time: Date.now(),
      text: "onClick action has performed for VODList - index: " + index,
      detail: null,
    });

    const response = await startPlaybackSession(index, false, this.props.updateTimeline);
    if (response.result) {
      // convert channel to the player source.
      const source = contentToPlayerSource(response.content);
      // notify parent component.
      this.props.onContentSelected({index:index, tab:this.props.tab}, source);
    } else {
      // show up the error message on screen.
      message.error(response.error);
    }
  };

  renderListItem = (item) => {
    return (
      <List.Item
        onClick={() => {
          this.onClick(item.index);
        }}
        style={ this.props.selectedItem !== null &&
          this.props.selectedItem.tab === this.props.tab &&
          this.props.selectedItem.index === item.index
            ? { backgroundColor: "#61A2D4" }
            : {}}
      >
        <List.Item.Meta title={item.title} description={item.description} />
      </List.Item>
    );
  };

  render() {
    const source = this.createListSource();
    return (
      <div
        id="scrollableDiv_vod"
        style={{
          height: 300,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={source.length}
          next={this.getVodListAndStore}
          hasMore={getLocalStorage(LOCAL_STORAGE_KEYS.HAS_MORE_VOD_LIST)}
          endMessage={<Divider plain>It is all, nothing more</Divider>}
          scrollableTarget="scrollableDiv_vod"
        >
          <List loading={this.isLoading} itemLayout="horizontal" dataSource={source} renderItem={this.renderListItem} />
        </InfiniteScroll>
      </div>
    );
  }
}
export default VodListTab;
